import { put, takeEvery } from "redux-saga/effects";
import axios from "axios";

const api_url = process.env.REACT_APP_API_URL
const config = {
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
};
function* chatSaga() {
  yield takeEvery("GET_CHAT", getChat);
  yield takeEvery("POST_CHAT", postChat);
  yield takeEvery("DELETE_CHAT", deleteChat);
  yield takeEvery("START_CHAT_DEMO", startChatDemo);
}

function* getChat(action) {
  try {
    const chat = yield axios.get(`${api_url}/api/chat/${action.payload}`, config);
    yield put({ type: "SET_CHAT", payload: chat.data });
  } catch (error) {
    console.log("error in getChat saga", error);
  }
}

function* postChat(action) {
  try {
    yield axios.post(`${api_url}/api/chat`, { payload: action.payload }, config);
    yield put({ type: "GET_CHAT", payload: streamId});
    yield action.callback();
  } catch (error) {
    console.log("Error in postChat saga:", error);
  }
}

function* deleteChat(action) {
  try {
    yield axios.delete(`${api_url}/api/chat/${action.payload}`, config);
    yield put({ type: "GET_CHAT" });
  } catch (error) {
    console.log(error);
  }
}

function* startChatDemo() {
  try {
    yield axios.get(`${api_url}/api/chat/start-demo`, config);
  } catch (error) {
    console.log(error);
  }
}

export default chatSaga;
