import { REHYDRATE } from 'redux-persist/lib/constants'; 

const INIT_STATE = [
    {
        "id": "cdcfa13e-e0c5-11ee-b92b-0242c0f8d001",
        "text": "Hello Streamer",
        "timestamp": "2023-10-13T14:16:34.188Z",
        "username": "admin0"
    },
    {
        "id": "abcfa13e-e0c5-11ee-b92b-0242c0f8d001",
        "text": "Hello Streamer",
        "timestamp": "2023-10-13T14:16:34.188Z",
        "username": "admin1"
    },
]

const chatReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case 'SET_CHAT':
            return action.payload;
        case REHYDRATE:
            return [...state];
        default:
            return state;
    }
};

export default chatReducer;
