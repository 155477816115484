import React from 'react';

import RegisterForm from '../RegisterForm/RegisterForm';

function RegisterPage() {

  return (
    <>
      <RegisterForm />
    </>
  );
}

export default RegisterPage;
