import { Box, Button, TextField, ListItem } from '@mui/material'
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import OtpInput from 'react-otp-input'; //docs: https://www.npmjs.com/package/react-otp-input

function EmailVerification(props) {
    const [otp, setOtp] = useState('');
    const errors = useSelector((store) => store.errors);
    const dispatch = useDispatch();
    const history = useHistory();
    const user = useSelector((store) => store.user);
    let firstDigit = otp[0]
    let secondDigit = otp[1]
    let thirdDigit = otp[2]
    let fourthDigit = otp[3]
    let fifthDigit = otp[4]
    let sixthDigit = otp[5]

    const verifyUser = (event) => {
        event.preventDefault();
        if (props.email && props.username && props.password && otp.length === 6) {
            let oneTimeCode = firstDigit + secondDigit + thirdDigit + fourthDigit + fifthDigit + sixthDigit
            dispatch({
                type: "VERIFY_USER",
                payload: {
                    code: oneTimeCode,
                    email: props.email,
                    username: props.username,
                    password: props.password,
                }
            })
            history.push("/home");
        }
        else {
            dispatch({ type: "VERIFICATION_INPUT_ERROR" })
        }
    }

    const resendVerificationEmail = (event) => {
        event.preventDefault();
        if (props.email) {
            dispatch({
                type: "RESEND_VERIFICATION_CODE",
                payload: {
                    email: props.email,
                }
            })
        }
        else {
            dispatch({ type: "RESENT_VERIFICATION_NO_EMAIL" })
        }
    }
    return (
        <>
            <form onSubmit={verifyUser}>
                <Box sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    margin: "0 1rem",
                    padding: "0.125rem",
                    height: "100vh",
                }} >
                    <Box sx={{
                        backgroundColor: "#fff",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "4rem 2rem",
                        borderRadius: "0.5rem",
                        gap: "2rem",
                        position: "relative",
                        overflow: "hidden",
                        width: "800px"

                    }}>
                        <img
                            style={{ position: "absolute", top: "0", left: "0" }}
                            src="/assets/images/leafLeft.png" alt="" />
                        <img
                            style={{ position: "absolute", bottom: "0", right: "0" }}
                            src="/assets/images/leafRight.png" alt="" />

                        <Box sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            gap: "8px",
                            boxShadow: "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)",
                            width: "384px",
                            padding: "1rem 1rem",
                            zIndex: "1",
                            backgroundColor: "#fff",
                            borderRadius: "8px",
                            width: "560px"
                        }}>
                            <Box
                                sx={{
                                    textAlign: "center",
                                    marginBottom: "24px"
                                }}>
                                <h1 style={{ fontWeight: "bold", color: "#32324D" }}>Email Verification</h1>
                                <p style={{ color: "#8c8c8c", fontSize: "14px" }}>Please enter the six digit code sent to your email address</p>
                            </Box>
                            <Box sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-around",
                                gap: "0 8px",
                                marginBottom: "28px"
                            }}>
                                <OtpInput
                                    value={otp}
                                    onChange={setOtp}
                                    numInputs={6}
                                    renderSeparator={
                                        <span
                                            style={{
                                                fontSize: "8px",
                                                marginLeft: "4px",
                                                marginRight: "4px",
                                            }}
                                        >
                                            {" "}
                                        </span>
                                    }
                                    renderInput={(props) => <input {...props} />}
                                    inputType='tel'
                                    shouldAutoFocus
                                    inputStyle={{
                                        width: "40px",
                                        height: "40px",
                                    }}
                                />
                            </Box>
                            <Button sx={{
                                width: "24rem",
                                fontSize: "24px",
                                fontFamily: "Poppins",
                            }} type="submit">
                                Next
                            </Button>
                            <Box>
                                <p style={{ fontSize: "12px", color: "#283252" }}>Trouble with the verification code?
                                    <button
                                        style={{ marginLeft: "4px" }}
                                        type="button"
                                        className="btn btn_asLink"
                                        onClick={resendVerificationEmail}
                                    >
                                        Click to Resend
                                    </button>
                                </p>
                            </Box>
                            {errors.verificationMessage && (
                                <Box
                                    sx={{ width: "50%", textAlign: "center", margin: "0.125rem auto" }}
                                    className="alert"
                                    role="alert"
                                >
                                    {errors.verificationMessage}
                                </Box>
                            )}
                        </Box>
                    </Box>
                </Box>
            </form>
        </>
    )
}

export default EmailVerification