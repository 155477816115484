import { REHYDRATE } from 'redux-persist/lib/constants'; 

const INIT_STATE = {
  "id":"d95dde08-8800-11ee-8e9a-0242ac120002",
  "name":"Random User",
  "username":"randomName57",
  "email":"noreply@customer.email",
  "date_of_birth":"04/12/1945",
  "affiliate_coupon_code":"XXXXXX",
  "is_streamer": false,
  "is_moderator":false,
  "is_admin":false,
  "is_verified":true,
  "stream_key":"klnafjnlaf",
  "visits":null
}

const userReducer = (state = {}, action) => {
  switch (action.type) {
    case 'SET_USER':
      return action.payload;
    case 'UNSET_USER':
      return {};
    case REHYDRATE:
        return { ...state, persistedState: action.payload };
    default:
      return state;
  }
};

// user will be on the redux state at:
// state.user
export default userReducer;
