import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import {
  Button,
  TextField,
  InputLabel,
  Typography,
  Box,
  CardMedia,
  useTheme,
} from "@mui/material";
//imports for picking date
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
//import Public component
import Public from "../../ProductDetail/Public/Public";

import ConfirmationPopup from "../../ConfirmationPopup/ConfirmationPopup";
import { CloudUpload } from "@mui/icons-material";

function ProductForm() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const history = useHistory();

  // id of product from the URL
  const { productID } = useParams();
  const currentProduct = useSelector((store) => store.currentProduct);

  const [productName, setProductName] = useState("");
  const [productDesc, setProductDesc] = useState("");
  const [imgUrl, setImgUrl] = useState(
    "https://images.unsplash.com/photo-1607873637553-132acdc40181?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
  );
  const [productLink, setProductLink] = useState("");
  const [productCoupon, setProductCoupon] = useState("");
  const [couponExp, setCouponExp] = useState("2023-12-11T18:30:00.000Z");

  const [image1, setImage1] = useState(null);
  const [image2, setImage2] = useState(null);
  const [image3, setImage3] = useState(null);

  const inputRef1 = React.createRef();
  const inputRef2 = React.createRef();
  const inputRef3 = React.createRef();

  const handleImageUpload = (event, setImage) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setImage(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const [category, setCategory] = useState("");

  const handleCatChange = (e) => {
    setCategory(e.target.value);
  };

  const [displayConfirmCancel, setDisplayConfirmCancel] = useState(false);

  const [newProduct, setNewProduct] = useState({
    id: productID,
    name: "",
    image_url: "",
    description: "",
    url: "",
    platform_coupon_code: "",
  });

  useEffect(() => {
    console.log("newProduct", newProduct);
  });

  const [couponExpiration, setCouponExpiration] = useState(
    currentProduct ? currentProduct.platform_coupon_expiration : ""
  );

  useEffect(() => {
    if (productID) {
      dispatch({
        type: "FETCH_PRODUCT_BY_ID",
        payload: productID,
      });
    }
    return () => {
      dispatch({
        type: "UNSET_CURRENT_PRODUCT",
      });
    };
  }, []);

  useEffect(() => {
    setNewProduct(currentProduct);
  }, [currentProduct]);

  const handleChange = (e, key) => {
    setNewProduct({ ...newProduct, [key]: e.target.value });
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    dispatch({
      type: "UPDATE_PRODUCT",
      payload: { ...newProduct, platform_coupon_expiration: couponExpiration },
    });

    history.push(`/product/${productID}`);
  };

  //check currentStream to see if this product is part of a s
  const currentStream = useSelector((store) => store.currentStream);

  const handleAdd = () => {
    console.log("add product");
    dispatch({
      type: "ADD_PRODUCT",
      payload: {
        name: productName,
        image_url: imgUrl,
        url: productLink,
        description: productDesc,
        coupon_code: productCoupon,
        coupon_expiration: couponExp,
        streamID: currentStream?.id,
      },
    });
    // if (currentStream.id) {
    //   history.push(`/edit-stream/${currentStream.id}`);
    // } else {
    //   history.push(`/home/:view`);
    // }
  };

  const confirmCancel = () => {
    if (productID) {
      history.push(`/product/${productID}`);
    } else {
      history.goBack();
    }
  };

  const uploadContainerStyle = {
    padding: 10,
    margin: 5,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#cfcfcf",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    borderRadius: "5px",
    cursor: "pointer",
    // height: "45%",
    // position: "relative",
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "95%",
          height: "100vh",
          backgroundColor: "#BADAD9",
          color: "black",
        }}
      >
        <span
          style={{
            // paddingLeft: 32,
            // paddingTop: 32,
            fontSize: 32,
            fontWeight: 600,
            width: "100%",
            textAlign: "center",
          }}
        >
          Add Product Info
        </span>
        <div
          id="content wrapper"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "32px",
            height: "100%",
            width: "100%",
          }}
        >
          <div
            id="content"
            style={{
              borderRadius: "16px",
              backgroundColor: "white",
              height: "100%",
              width: "90%",
              padding: 20,
              display: "flex",
            }}
          >
            <div
              id="leftContent"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-evenly",
                height: "100%",
                width: "55%",
              }}
            >
              <div
                style={{ display: "flex", flexDirection: "column", gap: 16 }}
              >
                <span style={{ fontWeight: 600 }}>Product Name</span>
                <input
                  style={{
                    borderWidth: "1px",
                    borderColor: "black",
                    padding: 6,
                    borderRadius: 5,
                  }}
                  onChange={(e) => setProductName(e.target.value)}
                  placeholder="Enter Product Name"
                />
              </div>
              <div
                style={{ display: "flex", flexDirection: "column", gap: 16 }}
              >
                <span style={{ fontWeight: 600 }}>Product Description</span>
                <textarea
                  style={{
                    borderWidth: "1px",
                    borderColor: "black",
                    padding: 6,
                    height: 150,
                    borderRadius: 5,
                  }}
                  onChange={(e) => setProductDesc(e.target.value)}
                  placeholder="Enter Product Description"
                />
              </div>
              <div
                style={{ display: "flex", flexDirection: "column", gap: 16 }}
              >
                <span style={{ fontWeight: 600 }}>Link</span>
                <input
                  style={{
                    borderWidth: "1px",
                    borderColor: "black",
                    padding: 6,
                    borderRadius: 5,
                  }}
                  onChange={(e) => setProductLink(e.target.value)}
                  placeholder="Enter Product Link"
                />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 16,
                    width: "48%",
                  }}
                >
                  <span style={{ fontWeight: 600 }}>Vendor Name</span>
                  <input
                    style={{
                      borderWidth: "1px",
                      borderColor: "black",
                      padding: 6,
                      borderRadius: 5,
                    }}
                    onChange={(e) => handleChange(e, "vendor_name")}
                    placeholder="Enter Vendor Name"
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 16,
                    width: "48%",
                  }}
                >
                  <span style={{ fontWeight: 600 }}>Coupon Code</span>
                  <input
                    style={{
                      borderWidth: "1px",
                      borderColor: "black",
                      padding: 6,
                      borderRadius: 5,
                    }}
                    onChange={(e) => setProductCoupon(e.target.value)}
                    placeholder="Enter Coupon Code"
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 16,
                    width: "48%",
                  }}
                >
                  <span style={{ fontWeight: 600 }}>Category</span>
                  <FormControl fullWidth>
                    <InputLabel id="category-label">Select Category</InputLabel>
                    <Select
                      labelId="category-label"
                      id="category-select"
                      value={category}
                      onChange={handleCatChange}
                      style={{
                        borderWidth: "1px",
                        borderColor: "black",
                        borderRadius: 5,
                      }}
                    >
                      <MenuItem value="category1">Category 1</MenuItem>
                      <MenuItem value="category2">Category 2</MenuItem>
                      <MenuItem value="category3">Category 3</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 16,
                    width: "48%",
                  }}
                >
                  <span style={{ fontWeight: 600 }}>Expiration</span>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      defaultValue={
                        productID
                          ? dayjs(currentProduct?.platform_coupon_expiration)
                          : null
                      }
                      date={couponExpiration}
                      minDate={dayjs()}
                      onChange={(date) => setCouponExpiration(date)}
                      fullWidth
                      sx={{
                        mb: "15px",
                      }}
                    />
                  </LocalizationProvider>
                </div>
              </div>
              <div style={{ width: "100%", paddingTop: 16 }}>
                <button
                  style={{
                    backgroundColor: "#167798",
                    padding: 7,
                    borderRadius: 5,
                    color: "white",
                    fontWeight: 600,
                  }}
                  onClick={() => {
                    console.log("add product");
                    dispatch({
                      type: "ADD_PRODUCT",
                      payload: {
                        name: productName,
                        image_url: imgUrl,
                        url: productLink,
                        description: productDesc,
                        coupon_code: productCoupon,
                        coupon_expiration: couponExp,
                        streamID: currentStream?.id,
                      },
                    });
                  }}
                >
                  Save
                </button>
              </div>
            </div>
            <div
              id="rightContent"
              style={{
                display: "flex",
                flexDirection: "column",
                // alignItems: "center",
                justifyContent: "center",
                width: "45%",
                paddingLeft: 32,
              }}
            >
              <div style={{ display: "flex", height: "40%" }}>
                <div
                  style={uploadContainerStyle}
                  onClick={() => inputRef1.current.click()}
                >
                  {image1 ? (
                    <img
                      src={image1}
                      alt="Uploaded"
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "5px",
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    <>
                      <CloudUpload />
                      <span>Drag and Drop Your Files Here</span>
                    </>
                  )}
                  <input
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    ref={inputRef1}
                    onChange={(event) => handleImageUpload(event, setImage1)}
                  />
                </div>
                <div
                  style={uploadContainerStyle}
                  onClick={() => inputRef2.current.click()}
                >
                  {image2 ? (
                    <img
                      src={image2}
                      alt="Uploaded"
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "5px",
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    <>
                      <CloudUpload />
                      <span>Drag and Drop Your Files Here</span>
                    </>
                  )}
                  <input
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    ref={inputRef2}
                    onChange={(event) => handleImageUpload(event, setImage2)}
                  />
                </div>
              </div>
              <div
                style={{
                  padding: 10,
                  margin: 5,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#cfcfcf",
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: "5px",
                  cursor: "pointer",
                  height: "45%",
                  // position: "relative",
                }}
                onClick={() => inputRef3.current.click()}
              >
                {image3 ? (
                  <img
                    src={image3}
                    alt="Uploaded"
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "5px",
                      objectFit: "cover",
                    }}
                  />
                ) : (
                  <>
                    <CloudUpload />
                    <span>Drag and Drop Your Files Here</span>
                  </>
                )}
                <input
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  ref={inputRef3}
                  onChange={(event) => handleImageUpload(event, setImage3)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductForm;
