import { put, takeEvery } from "redux-saga/effects";
import axios from "axios";

const api_url = process.env.REACT_APP_API_URL
const config = {
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
};

function* searchEverything(action) {
  try {
    let response = yield axios.get(`${api_url}/api/search/${action.payload}`, config);
    yield put({ type: "SET_SEARCH_RESULTS", payload: response.data });
  } catch (error) {
    console.log("error with SEARCH get request", error);
    yield put({ type: "FETCH_ERROR", payload: error });
  }
}

function* getProducts() {
  try {
    let response = yield axios.get(`${api_url}/api/products`, config);
    yield put({ type: "SET_ALL_PRODUCTS", payload: response.data });
  } catch (error) {
    console.log("error with element get request", error);
    yield put({ type: "FETCH_ERROR", payload: error });
  }
}

// worker Saga: will be fired on "REGISTER" actions
function* fetchProductByID(action) {
  try {
    const response = yield axios.get(`${api_url}/api/products/${action.payload}`, config);
    yield put({ type: "SET_CURRENT_PRODUCT", payload: response.data });
  } catch (error) {
    console.log("Error with get product by ID:", error);
  }
}

// POST
function* postProduct(action) {
  try {
    yield axios.post(`${api_url}/api/products`, { payload: action.payload }, config);
    yield put({ type: "GET_PRODUCTS" });
    if (action.payload.streamID) {
      yield put({
        type: "FETCH_STREAM_BY_ID",
        payload: { streamID: action.payload.streamID },
      });
    }
  } catch (error) {
    console.log("error with element get request", error);
    yield put({ type: "FETCH_ERROR", payload: error });
  }
}

// DELETE route for streamer to remove a product from the DB
function* deleteProduct(action) {
  try {
    yield axios.delete(`${api_url}/api/products/${action.payload}`, config);
    yield put({ type: "GET_PRODUCTS" });
  } catch (error) {
    console.log("error with element get request", error);
    yield put({ type: "FETCH_ERROR", payload: error });
  }
}

//UPDATE products
function* updateProduct(action) {
  try {
    yield axios.put(`${api_url}/api/products/${action.payload.id}`, action.payload, config);
    yield put({ type: "GET_PRODUCTS" });
  } catch (error) {
    console.log("error in product PUT", error);
  }
}

function* updateProductPublicStatus(action) {
  try {
    yield axios.put(`${api_url}/api/products/public/${action.payload.productID}`, {
      public: action.payload.public,
    }, config);
  } catch (error) {
    console.log("error with product PUT public status request", error);
    yield put({ type: "FETCH_ERROR", payload: error });
  }
}

function* productsSaga() {
  yield takeEvery("SEARCH_EVERYTHING", searchEverything);
  yield takeEvery("FETCH_PRODUCT_BY_ID", fetchProductByID);
  yield takeEvery("GET_PRODUCTS", getProducts);
  yield takeEvery("ADD_PRODUCT", postProduct);
  yield takeEvery("DELETE_PRODUCT", deleteProduct);
  yield takeEvery("UPDATE_PRODUCT", updateProduct);
  yield takeEvery("UPDATE_PRODUCT_PUBLIC_STATUS", updateProductPublicStatus);
}

export default productsSaga;
