import { put, takeLatest } from "redux-saga/effects";
import axios from "axios";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const api_url = process.env.REACT_APP_API_URL
const config = {
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
};

// worker Saga: will be fired on "REGISTER" actions
function* registerUser(action) {
  const history = useHistory();

  try {
    // clear any existing error on the registration page
    yield put({ type: "CLEAR_REGISTRATION_ERROR" });

    // history = action.payload.history
    // delete action.payload["history"]

    // passes the username and password from the payload to the server
    yield axios.post(`${api_url}/api/user/register`, action.payload, config);
    history.push({
      pathname: "/verification",
      state: {
        email: email,
        password: password,
      },
    });
  } catch (error) {
    console.log("Error with user registration:", error);
    yield put({ type: "REGISTRATION_FAILED", payload: error });
  }
}

function* verifyUser(action) {
    try {
        let oneTimeCode = action.payload.code
        let loginPayload = {
            username: action.payload.username,
            password: action.payload.password,
        }
        yield axios.post(`${api_url}/api/verification/verify-user`, oneTimeCode, config);
        // automatically log a user in after registration
        yield put({ type: "LOGIN", payload: loginPayload });
        // set to 'login' mode so they see the login screen
        // after registration or after they log out
        yield put({ type: "SET_TO_LOGIN_MODE" });
    }
    catch (error) {
        console.log("Error with user verification:", error);
        yield put({ type: "VERIFICATION_FAILED" });
    }
}

function* resendVerificationCode(action) {
    try {
        yield axios.post(`${api_url}/api/verification/refresh-otac`, action.payload, config);
        yield put({ type: "RESEND_VERIFICATION_CODE_SUCCESS" });
    }
    catch (error) {
        console.log("Error resending verification code:", error);
        yield put({ type: "RESEND_VERIFICATION_FAILED" });
    }
}

function* registrationSaga() {
  yield takeLatest("REGISTER", registerUser);
  yield takeLatest("VERIFY_USER", verifyUser);
  yield takeLatest("RESEND_VERIFICATION_CODE", resendVerificationCode)
}

export default registrationSaga;
