import React, { useState, useRef, useEffect, useMemo } from "react";
import LiveVideo from "../LiveVideo/LiveVideo";
import { useParams, useHistory, Link } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import RefreshIcon from "@mui/icons-material/Refresh";
import { IconButton, Typography, Box } from "@mui/material";
import Chat from "../../Chat/Chat";
import { useTheme } from "@emotion/react";
import ProductOverlay from "../ProductOverlay/ProductOverlay";
import { useSelector, useDispatch } from "react-redux";
import ConfirmationPopup from "../../ConfirmationPopup/ConfirmationPopup";

import { socket } from "../../../socket";
import {
  FavoriteBorderOutlined,
  FavoriteOutlined,
  HeartBroken,
  KeyboardArrowDown,
  KeyboardArrowUp,
  Share,
  Storefront,
  ViewCarousel,
} from "@mui/icons-material";

function StreamView({ height, width, chatHeight, username, streamID, yOffset, preview }) {
  const user = useSelector((store) => store.user);
  const currentProduct = useSelector((store) => store.currentProduct);
  console.log(currentProduct, "hahahas");
  // const streamID = useSelector((store) => store.streams.activeStreams);
  const history = useHistory();
  const dispatch = useDispatch();
  username = username ? username : useParams().username;
  streamID = streamID ? streamID : useParams().streamID;
  const playerRef = useRef(null);
  const [muted, setMuted] = useState(true);
  const [live, setLive] = useState(true);
  const theme = useTheme();
  const [viewerCount, setViewerCount] = useState(0);
  const [displayConfirmEndStream, setDisplayConfirmEndStream] = useState(false);
  const [showProducts, setShowProducts] = useState(false);
  const [liked, setLiked] = useState(false);
  const currentStream = useSelector((store) => store.currentStream);
  // const activeStreams = useSelector((store) => store.streams.activeStreams);

  useEffect(() => {
    if (playerRef?.current?.options_?.id) {
      document.getElementById(
        playerRef.current.options_.id + "_html5_api"
      ).muted = muted;
    }
  }, [muted, playerRef]);

  useEffect(() => {
    dispatch({
      type: "FETCH_STREAM_BY_ID",
      payload: { streamID: streamID },
    });
  }, []);

  const Product = ({ index, imgUrl, title, desc }) => {
    return (
      <div
        key={index}
        style={{
          display: "flex",
          // width: "50v",
          height: "300px",
          flexDirection: "column",
          justifyContent: "space-around",
          alignItems: "flex-start",
        }}
      >
        <img src={imgUrl} style={{ height: "150px" }} />
        <span style={{ fontWeight: "600", fontSize: "20px" }}>{title}</span>
        <span style={{ fontSize: "14px" }}>{desc}</span>
      </div>
    );
  };

  useEffect(() => {
    dispatch({ type: "FETCH_ACTIVE_STREAMS" });
    socket.emit("join stream", user.id);
    //create socket listener for stream closed emit, set live = false
    socket.on("stream_closed", (user) => {
      setDisplayConfirmEndStream(true);

      if (user === username) {
        // setTimeout(() => setLive(false), 5000)
        setLive(false);
      }
    });
    socket.on("update viewer count", (count) => {
      setViewerCount(count);
    });
    return () => {
      socket.emit("leave stream", streamID);
      socket.off("stream_closed");
      socket.off("update viewer count");
    };
  }, []);

  const toggleMute = () => {
    setMuted(!muted);
  };

  const endStream = () => {
    // socket.emit("stream_closed", user.username);
    // TODO: end stream for all users
    // dispatch({ type: "END_STREAM", payload: streamID });
    history.push(`/home`);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "92vh",
        width: "100%",
      }}
    >
      <Box
        id="viewWrapper"
        sx={{
          display: "flex",
          height: "100%",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <Box
          id="stream and products"
          sx={{
            width: "67%",
            height: "100%",
            backgroundColor: "#949494",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            position: "relative",
          }}
        >
          <LiveVideo
            username={username}
            playerRef={playerRef}
            setLive={currentStream.is_live}
            streamID={streamID}
            vodURL={currentStream.vod_url}
          />
          <Box
            sx={{
              width: "100%",
              height: "100%",
              backgroundColor: "#EBFFF6",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              paddingX: "32px",
              paddingY: "32px",
            }}
          >
            <Box
              id="product image and desc"
              sx={{
                display: "flex",
                alignItems: "center",
                width: "80%",
                // height: "70%"
              }}
            >
              <img
                src={currentProduct.image_url}
                style={{
                  height: "60px",
                  width: "60px",
                  borderRadius: "5px",
                  backgroundColor: "#EBFFF6",
                }}
              />
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  sx={{
                    fontSize: "1.2vw",
                    width: "60%",
                    marginLeft: "8px",
                    fontWeight: 800,
                  }}
                >
                  {currentProduct.name}
                </Typography>
                <Typography
                  sx={{ fontSize: "1.2vw", width: "60%", marginLeft: "8px" }}
                >
                  {currentProduct.description}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "16px",
                width: "20%",
              }}
            >
              {liked ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={() => setLiked(!liked)}
                >
                  <FavoriteOutlined
                    sx={{ cursor: "pointer", fontSize: "2rem" }}
                  />
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={() => setLiked(!liked)}
                >
                  <FavoriteBorderOutlined
                    sx={{ cursor: "pointer", fontSize: "2rem" }}
                  />
                </div>
              )}

              <Share sx={{ cursor: "pointer", fontSize: "2rem" }} />
              <Storefront sx={{ cursor: "pointer", fontSize: "2rem" }} />
            </Box>
          </Box>
          {!showProducts ? (
            <div
              onClick={() => setShowProducts(true)}
              style={{
                backgroundColor: "#EBFFF6",
                height: "25px",
                width: "100%",
                position: "absolute",
                bottom: 0,
                left: 0,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
            >
              <KeyboardArrowUp />
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                height: "50vh",
                position: "absolute",
                bottom: 0,
                left: 0,
                backgroundColor: "white",
                zIndex: 20,
              }}
            >
              <div
                style={{
                  backgroundColor: "#EBFFF6",
                  height: "25px",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
                onClick={() => setShowProducts(false)}
              >
                <KeyboardArrowDown />
              </div>
              <div
                style={{
                  display: "flex",
                  width: "90%",
                  height: "100%",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                }}
              >
                {currentStream?.products?.map((product, index) => {
                  return (
                    <Product
                      index={index}
                      imgUrl={product.image_url}
                      title={product.name}
                      desc={product.description}
                    />
                  );
                })}
              </div>
            </div>
          )}
        </Box>
        <Box
          id="chat"
          sx={{
            width: "33%",
            height: "100%",
            backgroundColor: "lightcoral",
            overflow: "hidden",
            zIndex: 5,
          }}
        >
          <Chat viewerCount={viewerCount} streamId={streamID}  />
        </Box>
      </Box>
    </Box>
  );
}

export default StreamView;
