import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux';
import { store, persistor } from '../../redux/store';
// import rootSaga from '../../redux/sagas/_root.saga'
import React, { useEffect } from "react";
import {
  HashRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import Navbar from "../Navbar/Navbar";

import ProtectedRoute from "../ProtectedRoute/ProtectedRoute";
import StreamerProtectedRoute from "../ProtectedRouteDefs/StreamerProtectedRoute";
import AdminProtectedRoute from "../ProtectedRouteDefs/AdminProtectedRoute";

import AboutPage from "../AboutPage/AboutPage";
import StreamView from "../ViewerComponents/StreamView/StreamView";
import LandingPage from "../LandingPage/LandingPage";
import LoginPage from "../LoginPage/LoginPage";
import RegisterPage from "../RegisterPage/RegisterPage";
import StreamerHomePage from "../StreamerComponents/StreamerHomePage/StreamerHomePage";
import ViewerHomePage from "../ViewerComponents/ViewerHomePage/ViewerHomePage";
import EditStream from "../StreamerComponents/EditStream/EditStream";
import ProductDetail from "../ProductDetail/ProductDetail";
import ProductForm from "../StreamerComponents/AddEditProduct/ProductForm";
import ViewerProductsList from "../ViewerComponents/ViewerProductsList/ViewerProductsList";
import AddExistingProduct from "../StreamerComponents/AddExistingProduct/AddExistingProduct";
import StreamerControls from "../StreamerComponents/StreamerControls/StreamerControls";
import EmailVerification from "../EmailVerification/EmailVerification";
import StreamerApplication from "../StreamerApplication/StreamerApplication";
import Searchpage from "../SearchPage/SearchPage";
import PaymentPortal from "../PaymentPortal/PaymentPortal";

import "./App.css";
import ForgotPassword from "../ForgotPassword/ForgotPassword";
import ViewerVideoList from '../VideosPage/ViewerVideoList';
import SettingsPage from '../Settings/SettingsPage';

function App() {
  const dispatch = useDispatch();
  const lightBlue = "#badad9";
  const user = useSelector((store) => store.user);
  // console.log(reduxStore)
  useEffect(() => {
    dispatch({ type: "FETCH_USER" });
  }, [dispatch]);

  return (
    <Router>
      <Provider store={ store }>
        <PersistGate loading={null} persistor={persistor}>
          <div>
            {/* If the user is logged in, display the navbar bar */}
            {/* {user.id &&} */}

            <Switch>
              {/* Visiting localhost:3000 will redirect to localhost:3000/home */}
              {/* <Redirect exact from="/" to="/home" /> */}

              {/* Visiting localhost:3000/about will show the about page. */}
              <Route
                // shows AboutPage at all times (logged in or not)
                exact
                path="/about"
              >
                <Navbar color={lightBlue} />
                <AboutPage />
              </Route>

              <Route exact path="/forgot-password">
                <ForgotPassword />
              </Route>

              {/* For protected routes, the view could show one of several things on the same route.
                Visiting localhost:3000/user will show the UserPage if the user is logged in.
                If the user is not logged in, the ProtectedRoute will show the LoginPage (component).
                Even though it seems like they are different pages, the user is always on localhost:3000/user */}

              <ProtectedRoute exact path="/live/:username/:streamID">
                <Navbar />
                <StreamView height="100vh" width="100vw" chatHeight="20vh" />
              </ProtectedRoute>

              <ProtectedRoute exact path="/home">
                <Navbar color={lightBlue}/>
                {user.is_streamer ? <StreamerHomePage /> : <ViewerHomePage />}
              </ProtectedRoute>

              <ProtectedRoute exact path="/settings">
                <Navbar color={lightBlue}/>
                <SettingsPage/>
              </ProtectedRoute>

              <ProtectedRoute exact path="/search/:query">
                <Navbar color={lightBlue} />
                <Searchpage  />
              </ProtectedRoute>

              <ProtectedRoute exact path="/search/">
                <Navbar color={lightBlue} />
                <Searchpage  />
              </ProtectedRoute>

              <StreamerProtectedRoute exact path="/home/:view">
                <Navbar />
                <StreamerHomePage />
              </StreamerProtectedRoute>

              <ProtectedRoute exact path="/viewer-products">
                <Navbar color={lightBlue}/>
                {<ViewerProductsList />}
              </ProtectedRoute>

              <ProtectedRoute exact path="/videos-on-demand">
                <Navbar color={lightBlue}/>
                {<ViewerVideoList />}
              </ProtectedRoute>

              

              <ProtectedRoute exact path="/wishlist">
                <Navbar color={lightBlue}/>
                {<ViewerProductsList wishlist={true} />}
              </ProtectedRoute>

              <ProtectedRoute exact path="/payment">
                <Navbar />
                {<PaymentPortal />}
              </ProtectedRoute>

              {/* route for displaying all the information for a specific product.
              This route will render differently depending on whether the user is a streamer or a viewer  */}
              <ProtectedRoute exact path="/product/:productID">
                <Navbar />
                <ProductDetail />
              </ProtectedRoute>

              <StreamerProtectedRoute exact path="/edit-stream">
                <Navbar />
                <EditStream />
              </StreamerProtectedRoute>

              <StreamerProtectedRoute exact path="/edit-stream/:streamID">
                <Navbar />
                <EditStream />
              </StreamerProtectedRoute>

              <StreamerProtectedRoute exact path="/productform/:productID">
                <Navbar />
                <ProductForm />
              </StreamerProtectedRoute>

              <StreamerProtectedRoute exact path="/productform/">
                <Navbar />
                <ProductForm />
              </StreamerProtectedRoute>

              <StreamerProtectedRoute
                // logged in shows InfoPage else shows LoginPage

                exact
                path="/add-existing-product/:streamID"
              >
                <Navbar />
                <AddExistingProduct />
              </StreamerProtectedRoute>

              <StreamerProtectedRoute
                // logged in shows InfoPage else shows LoginPage

                exact
                path="/streamer-stream/:streamID"
              >
                <Navbar />
                <StreamerControls />
              </StreamerProtectedRoute>

              <Route exact path="/login">
                {user.id ? (
                  // If the user is already logged in,
                  // redirect to the /user page
                  <Redirect to="/home" />
                ) : (
                  // Otherwise, show the login page
                  <>
                    <Navbar color={lightBlue} />
                    <LoginPage />
                  </>
                )}
              </Route>

              <Route exact path="/registration">
                {user.id ? (
                  // If the user is already logged in,
                  // redirect them to the /user page
                  <Redirect to="/home" />
                ) : (
                  // Otherwise, show the registration page
                  <>
                    <Navbar color={lightBlue} />
                    <RegisterPage />
                  </>
                )}
              </Route>

              <Route exact path="/verification">
                {user.id ? (
                  // If the user is already logged in,
                  // redirect them to the /home page
                  <Redirect to="/" />
                ) : (
                  // Otherwise, show the verification page
                  <>
                    <Navbar />
                    <EmailVerification />
                  </>
                )}
              </Route>

              <Route exact path="/streamerapplication">
                {user.id ? (
                  // If the user is already logged in,
                  // redirect them to the /home page
                  <Redirect to="/" />
                ) : (
                  // Otherwise, show the streamer application page
                  <>
                    <Navbar />
                    <StreamerApplication />
                  </>
                )}
              </Route>

              <Route exact path="/">
                {user.id ?
                  <Redirect to="/home" /> :
                  <>
                    <Navbar color={lightBlue} />
                    <LandingPage />
                  </>
                }
              </Route>

              {/* If none of the other routes matched, we will show a 404. */}
              <Route>
                <h1>404</h1>
              </Route>
            </Switch>
          </div>
        </PersistGate>
      </Provider>
    </Router>
  );
}

export default App;
