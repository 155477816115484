import { Box, Button } from "@mui/material";
import React, { useState } from "react";
import OtpInput from "react-otp-input";
import { useDispatch } from "react-redux";

const ForgotPassword = () => {
  const [forgotStep, setForgotStep] = useState(1);
  const [code, setCode] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const apiUrl = process.env.PORT;
  const dispatch = useDispatch();

  const sendEmail = () => {
    dispatch({
      type: "SEND_EMAIL",
      payload: { email: email },
    });
    setForgotStep(2);
  };
  const verifyUser = () => {
    dispatch({
      type: "VERIFY_USER",
      payload: { email: email, otac: code },
    });
    setForgotStep(3);
  };

  const resetPassword = () => {
    dispatch({
      type: "RESET_PASSWORD",
      payload: {
        email: email,
        password: password,
        confirm_password: confirmPassword,
      },
    });
    setForgotStep(4);
  };

  const handleChange = (code) => setCode(code);

  const renderInput = (input, index) => {
    return (
      <input
        {...input}
        key={index}
        style={{
          width: "40px",
          height: "50px",
          marginRight: "16px",
          fontSize: "16px",
          textAlign: "center",
          backgroundColor: "#e6e6e6",
        }}
      />
    );
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "100%",
          backgroundColor: "white",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
          overflow: "hidden",
          borderRadius: 1,
          boxShadow: "4px 4px 80px -59px rgba(0,0,0,1)",
        }}
      >
        <div
          style={{
            width: "300px",
            height: "300px",
            backgroundColor: "#ECF6FF",
            borderRadius: "150px",
            position: "absolute",
            left: -90,
            top: -90,
          }}
        />
        <div
          id="hello blob"
          style={{
            width: "300px",
            height: "300px",
            backgroundColor: "#ECF6FF",
            borderRadius: "150px",
            position: "absolute",
            right: -90,
            bottom: -90,
          }}
        />
        {forgotStep === 1 && (
          <Box
            sx={{
              width: "80%",
              height: "80%",
              backgroundColor: "white",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: 5,
              borderRadius: "5px",
              boxShadow: "4px 4px 80px -59px rgba(0,0,0,1)",
              zIndex: 50,
            }}
          >
            <span style={{ fontSize: 32, fontWeight: 600 }}>
              Forgot your password?
            </span>
            <span style={{ width: "45%", textAlign: "center", fontSize: 13 }}>
              No worries, enter the email address associated with your account
              and we’ll send a six digit code to confirm your identity.
            </span>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
              }}
            >
              <input
                type="text"
                placeholder="Email"
                style={{
                  height: "40px",
                  width: "45%",
                  backgroundColor: "#f7f7f7",
                  borderRadius: "25px",
                  paddingLeft: 20,
                }}
                onChange={(e) => setEmail(e.target.value)}
              />
              <span style={{ fontSize: 10 }}>
                Trouble with the verification code?{" "}
                <span style={{ color: "blue" }}>Click to Resend</span>{" "}
              </span>
            </Box>
            <Button
              onClick={sendEmail}
              sx={{ paddingX: 8, paddingY: 1.5, borderRadius: 50 }}
            >
              Send Email
            </Button>
          </Box>
        )}
        {forgotStep === 2 && (
          <Box
            sx={{
              width: "80%",
              height: "80%",
              backgroundColor: "white",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: 5,
              borderRadius: "16px",
              boxShadow: "4px 4px 80px -59px rgba(0,0,0,1)",
              zIndex: 50,
            }}
          >
            <span style={{ fontSize: 32, fontWeight: 600 }}>
              Email Verification
            </span>
            <span style={{ width: "45%", textAlign: "center", fontSize: 13 }}>
              Please enter the six digit code sent to {email}
            </span>
            <OtpInput
              value={code}
              onChange={handleChange}
              numInputs={6}
              isInputNum={true}
              shouldAutoFocus={true}
              renderInput={renderInput}
            />
            <span style={{ fontSize: 10 }}>
              Trouble with the verification code?{" "}
              <span style={{ color: "blue" }}>Click to Resend</span>{" "}
            </span>
            <Button
              onClick={verifyUser}
              sx={{ paddingX: 8, paddingY: 1.5, borderRadius: 50 }}
            >
              Next
            </Button>
          </Box>
        )}
        {forgotStep === 3 && (
          <Box
            sx={{
              width: "80%",
              height: "80%",
              backgroundColor: "white",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: 5,
              borderRadius: "16px",
              boxShadow: "4px 4px 80px -59px rgba(0,0,0,1)",
              zIndex: 50,
            }}
          >
            <span style={{ fontSize: 32, fontWeight: 600 }}>
              Reset Your Password
            </span>
            <span style={{ width: "45%", textAlign: "center", fontSize: 13 }}>
              Thank you for confirming your identity @yourusername!
              <br /> <br /> Note: Passwords must be at least 8 characters long,
              include one special character and at least one uppercase digit.
            </span>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <input
                type="text"
                placeholder="Enter Password"
                style={{
                  height: "40px",
                  width: "45%",
                  backgroundColor: "#f7f7f7",
                  borderRadius: "25px",
                  paddingLeft: 20,
                }}
                onChange={(e) => setPassword(e.target.value)}
              />
              <input
                type="text"
                placeholder="Confirm Password"
                style={{
                  height: "40px",
                  width: "45%",
                  backgroundColor: "#f7f7f7",
                  borderRadius: "25px",
                  paddingLeft: 20,
                }}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </Box>
            <Button
              onClick={resetPassword}
              sx={{ paddingX: 8, paddingY: 1.5, borderRadius: 50 }}
            >
              Confirm
            </Button>
          </Box>
        )}
        {forgotStep === 4 && (
          <Box
            sx={{
              width: "80%",
              height: "80%",
              backgroundColor: "white",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: 5,
              borderRadius: "16px",
              boxShadow: "4px 4px 80px -59px rgba(0,0,0,1)",
              zIndex: 50,
            }}
          >
            <span style={{ fontSize: 32, fontWeight: 600 }}>
              Password Reset Successful!
            </span>
            <span style={{ width: "45%", textAlign: "center", fontSize: 13 }}>
              You have successfully reset the password for your account. Please
              login using the link below.
            </span>
            <img
              src="https://media2.giphy.com/media/toPQKsvkZn12WROprz/200w.webp?cid=ecf05e477l8pppdy56hjxjzqth64ov4sxyrj2bzmy1soyh84&ep=v1_stickers_search&rid=200w.webp&ct=s"
              style={{ width: "20%" }}
            />
            <Button
              onClick={() => history.push("/")}
              sx={{ paddingX: 8, paddingY: 1.5, borderRadius: 50 }}
            >
              LOGIN
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ForgotPassword;
