import { useEffect, useState } from "react";
import {
  useTheme,
  Card,
  CardMedia,
  CardContent,
  Typography,
  CardActions,
  IconButton,
  Badge,
  Modal,
  Box,
  Button,
  CardActionArea,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import TooltipMUI from "@mui/material/Tooltip";
import { Bookmark } from "@mui/icons-material";
import ConfirmationPopup from "../ConfirmationPopup/ConfirmationPopup";
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';


function VideoListItem({stream}) {
  const theme = useTheme();
  const history = useHistory();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleBookmark = () => setBookmarkModal(true)
  const handleCloseBookmark = () => setBookmarkModal(false); 
  const [bookmarkModal, setBookmarkModal] = useState(false);
  const [open, setOpen] = useState(false);
//   const dispatch = useDispatch();
  const [displayConfirmBookmark, setDisplayConfirmBookmark] = useState(false);
//   const [displayShareMsg, setDisplayShareMsg] = useState(false);
  const currentStream = useSelector((store) => store.currentStream);
  const currentProduct = useSelector((store) => store.currentProduct);
  const dayjs = require("dayjs");
//   const handleBuy = () => {
//     window.open(product.url, "_blank");
//   };
  const handleWatchNow = () => {
    window.open(stream.vod_url, "_blank");
  }
  const user = useSelector((store) => store.user);

//   const handleClickShare = () => {
//     setDisplayShareMsg(true);
//     navigator.clipboard.writeText(product.url);
//   };

  const handleClickBookmark = () => {
    useEffect(() => {
        setDisplayConfirmBookmark();
    }, [])
  }

//   // reserved for streamers only, viewers do not need to add products to stream
//  refactor to bookmark (WIP) need schema update
//   const handleAddToStream = () => {
//     dispatch({
//       type: "ADD_PRODUCT_TO_STREAM",
//       payload: { productID: product.id, streamID: streamId },
//     });
//     dispatch({
//       type: "FETCH_STREAM_BY_ID",
//       payload: { streamID: streamId },
//     });
//     history.push(`/edit-stream/${streamId}`);
//   };

  /*   const handleGoToProduct = () => {
      history.push(`/product/${product.id}`);
    }; */

  return (
    <>
      {/* Bookmark Popup */}
      {bookmarkModal && (
        <ConfirmationPopup
          setDisplayConfirmation={handleCloseBookmark}
          handleConfirm={handleWatchNow}
          alertText={`Stream ${stream.title} has been bookmarked, would you like to watch it now?`}
          hidePopupText="KEEP SHOPPING"
          confirmPopupText="WATCH NOW"
          top="38vh"
        />
      )}
      {/* Old Modal Start */}
      <Modal
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiBackdrop-root": {
            bgcolor: "rgba(0,0,0,0.1)",
          },
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "50vw",
            height: "fit-content",
            bgcolor: "#fff",
            borderRadius: "5px",
            boxShadow: "0 1px 2px 0 rgb(0 0 0 / 0.05)",
            padding: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "0 32px",
            }}
          >
            <IconButton
              sx={{ position: "absolute", top: 12, right: 12 }}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
            <Box
              component="img"
              sx={{
                display: { xs: "none", xl: "block" },
                width: "90%",
                height: "288px",
                objectFit: "contain",
                borderRadius: "5px",
              }}
              alt=""
              src={stream.thumbnail_url}
              onError={({ currentTarget }) => {
                console.log("loop man")
                currentTarget.onerror = null; // prevents looping
                currentTarget.src="assets/images/download.png";
              }}
            />
            <Box>
              <Typography noWrap gutterBottom variant="h6" component="div" sx={{ fontWeight: "600" }}>{stream.title}</Typography>
              <Typography variant="body1" gutterBottom sx={{ fontWeight: "600" }}>Description</Typography>
              <Typography variant="body2" color="text.secondary" sx={{ textAlign: "justify" }}>{stream.description}</Typography>
              <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", margin:"2% 0" }}>
                <Box sx={{ display: "flex", justifyContent: "flex-start", gap: "0 8%"}}>
                  <Box sx={{ display: "flex", flexDirection: "column", gap: "0 8px" }}>
                    <Typography variant="body1" gutterBottom component="div" sx={{ fontWeight: "600" }}>Streamer</Typography>
                    <Typography variant="body2" color="text.secondary" gutterBottom component="div">{stream.streamer_username}</Typography>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column", gap: "0 8px" }}>
                    <Typography variant="body1" gutterBottom component="div" sx={{ fontWeight: "600" }}>Categories</Typography>

                    {/* <Typography variant="body2" color="text.secondary" gutterBottom component="div">
                      <Button size="small" variant="outlined" sx={{ borderRadius: "32px", fontSize: "8px", color: "black" }}> {stream.category != null ? product.category : "100% recycled"}</Button>
                      {/* {product.category} /}
                    </Typography> */}
                  </Box>
                </Box>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                <Box onClick={handleClose} textAlign='center' sx={{margin:"2% 0 0 0"}}>
                  {/* <Bookmark onClick={handleClickBookmark} /> */}
                </Box>
              </Box>
            </Box>
            
          </Box>
        </Box>
      </Modal>
      {/* Old Modal End */}

      <Card key={stream.id} sx={{ maxWidth: 380, borderRadius: "5px" }}>
            {/* If the stream is live, go to the streamers link, if its not, go to VOD link */}
            <CardActionArea onClick={() => history.push(stream.is_live == true ? `/live/${stream.streamer_username}/live` : `/live/${stream.streamer_username}/${stream.id}`)}>
                <CardMedia
                    component="img"
                    height="140"
                    image={stream.thumbnail}
                    alt="product"
                />
                <CardContent>
                    <Typography noWrap sx={{ display: { lg: "block" } }} gutterBottom variant="h6" component="div">
                        {stream.title}
                    </Typography>
                    <Typography noWrap sx={{ display: { xs: "none", lg: "block" } }} gutterBottom variant="h6" component="div">
                        @{stream.streamer_username}
                    </Typography>
                    <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{
                            display: { xs: "none", lg: "-webkit-box" },
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            WebkitLineClamp: 1,
                            WebkitBoxOrient: 'vertical'
                        }}
                    >
                        
                        <TooltipMUI title={stream.description}>
                            {stream.description}
                        </TooltipMUI>
                    </Typography>
                </CardContent>
            </CardActionArea>
            <CardActions disableSpacing sx={{ padding: "4px" }}>
                <Button 
                  onClick={() => {handleBookmark()}}
                  sx={{ border: '3px solid pink', }} variant="outlined" color="warning">
                    <BookmarkBorderOutlinedIcon/>
                    Bookmark Video
                </Button>
            </CardActions>
        </Card>
    </>
  );
}

export default VideoListItem;
