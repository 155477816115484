import { useSelector, useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { useEffect } from "react";
import dayjs from "dayjs";

import AddExistingProductItem from "./AddExistingProductItem/AddExistingProductItem";
import { Box, Button, Container, Pagination, Typography } from "@mui/material";
import ProductListItem from "../../ViewerComponents/ViewerProductsList/ProductListItem/ProductListItem";

function AddExistingProduct() {
  const { streamID } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const currentStream = useSelector((store) => store.currentStream);
  const products = useSelector((store) => store.allProducts);

  useEffect(() => {
    dispatch({ type: "GET_PRODUCTS" });
    dispatch({ type: "FETCH_STREAM_BY_ID", payload: { streamID: streamID } });
  }, []);

  return (
    <Box sx={{ padding: "0px 20px" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          width: "100%",
          alignItems: "center",
        }}
      >
        {/* <Box
          sx={{ fontSize: "1.5em", fontWeight: "bold", textAlign: "center" }}
        >
          ADD EXISTING PRODUCT TO STREAM
        </Box> */}
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            marginBottom: "10px",
            marginTop:"2.5%"
          }}
        >
          <Box sx={{ fontSize: "1.7em", width: "60%", }}>
            Adding product to <b>{currentStream.title}</b>
            {/* <br></br> */}
            {/* {dayjs(currentStream.scheduled).format("MM/DD/YYYY")} */}
          </Box>
          <Button
            size="large"
            sx={{ alignSelf: "center" }}
            onClick={() => history.push(`/edit-stream/${streamID}`)}
          >
            BACK TO STREAM
          </Button>
        </Box>
        <Container maxWidth="xl">
          <Box
            sx={{
              paddingBottom: "64px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {/* <Typography
              gutterBottom
              align="center"
              sx={{ fontSize: "24px", fontWeight: "600", letterSpacing: "1px" }}
            >
              {wishlist ? "Favorited Products" : "Product Catalog"}
            </Typography> */}
            <Box
              sx={{
                marginBottom: "64px",
                display: "grid",
                gridTemplateColumns: "repeat(3, minmax(380px, 1fr))",
                gap: "16px",
              }}
            >
              {products.map((product) => (
                <ProductListItem product={product} streamId={streamID} />
              ))}
              {/* {products.length ? (
                wishlist ? (
                  products
                    .filter((product) => product.on_user_wishlist)
                    .map((product) => <ProductListItem product={product} />)
                ) : (
                  products.map((product) => (
                    <ProductListItem product={product} />
                  ))
                )
              ) : (
                <Typography variant="body1">No products added yet.</Typography>
              )} */}
            </Box>
            <Pagination
              onChange={() => {
                console.log("clicked");
              }}
              size="large"
              count={10}
              color="primary"
            />
          </Box>
        </Container>
      </Box>
    </Box>
  );
}

export default AddExistingProduct;
