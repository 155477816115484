import React, { useState } from 'react'
import { Container, Typography, Box, Avatar, Link } from '@mui/material'
import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import userReducer from '../../redux/reducers/user.reducer';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import ConfirmationPopup from '../ConfirmationPopup/ConfirmationPopup';

function SettingsPage() {
    const history = useHistory()
    const dispatch = useDispatch()
    const user = useSelector((store) => store.user);
    const [displayDeleteAccountConfirmMsg, setDisplayDeleteAccountConfirmMsg] = useState(false);

    const handleDisplayDeleteAccountConfirmMsg = () => {
        setDisplayDeleteAccountConfirmMsg(true);
    }

    const handleDeleteAccount = () => {
        dispatch({
            type: "DELETE_USER_ACCOUNT"
        })
    }
    
    // functionality for MUI History table
    function createHistoryData(historyDate, historyDesc, historyAmount, historyInvoice) {
        return { historyDate, historyDesc, historyAmount, historyInvoice };
    }

    function formatDate (inputDate, format)  {
        if (!inputDate) return '';
    
        const padZero = (value) => (value < 10 ? `0${value}` : `${value}`);
        const parts = {
            yyyy: inputDate.getFullYear(),
            MM: padZero(inputDate.getMonth() + 1),
            dd: padZero(inputDate.getDate()),
            HH: padZero(inputDate.getHours()),
            hh: padZero(inputDate.getHours() > 12 ? inputDate.getHours() - 12 : inputDate.getHours()),
            mm: padZero(inputDate.getMinutes()),
            ss: padZero(inputDate.getSeconds()),
            tt: inputDate.getHours() < 12 ? 'AM' : 'PM'
        };
    
        return format.replace(/yyyy|MM|dd|HH|hh|mm|ss|tt/g, (match) => parts[match]);
    }

    return (
        <>
        {/* Share Button Popup */}
        {displayDeleteAccountConfirmMsg && (
            <ConfirmationPopup
            setDisplayConfirmation={setDisplayDeleteAccountConfirmMsg}
            handleConfirm={handleDeleteAccount}
            alertText={`Are you sure you want to delete your account? This cannot be undone.`}
            hidePopupText="GO BACK"
            confirmPopupText="DELETE ACCOUNT"
            top="38vh"
            />
        )}
        <Container 
            sx={{
                padding: "1rem",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-around",
            }}>

        
            <Box sx={{
                backgroundColor: "#fff",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                flexDirection: "column",
                padding: "1rem",
                borderRadius: "32px",
                gap: "1rem",
            }}>
            <Avatar sx={{ bgcolor: "#167798", height: "20vh", width: "20vh", mb:"1vh", fontSize: "10vh"  }}>{user.name.split(" ").length > 0 ? user.name.split(" ")[0][0] : "USA"}{ user.name.split(" ").length > 1 ? user.name.split(" ")[1][0] : ""}</Avatar>
                <Typography variant='h5' sx={{ fontWeight: "600", mb:"1vh" }}>{user.name}</Typography>
                {!user.is_streamer && 
                    <Box sx={{
                        display: {sm: "flex-column", md: "flex-column"},
                        gridTemplateColumns: "repeat(2, 1fr)",
                        // gridAutoRows: "minmax(0, 1fr)",
                        gap: "1rem",
                        width: "100%",
                    }}>
                            <Typography sx={{ fontWeight: "600" }} variant="h6" align="center">Username</Typography>
                            <Typography sx={{ fontWeight: "600" }} variant="h6" align="center" color="#BDC1D6">@{user.username}</Typography>
                            
                            <Typography sx={{ fontWeight: "600" }} variant="h6" align="center">Email</Typography>
                            <Typography sx={{ fontWeight: "600" }} variant="h6" align="center" color="#BDC1D6">{user.email}</Typography>
                            
                            <Typography sx={{ fontWeight: "600" }} variant="h6" align="center">Date of Birth</Typography>
                            <Typography sx={{ fontWeight: "600" }} variant="h6" align="center" color="#BDC1D6">{formatDate(new Date(user.date_of_birth), "MM/dd/yyyy")}</Typography>
                            
                            <Link onClick={() => history.push("/forgot-password")} sx={{ fontWeight: "600", m:"1vh" }} variant="h7" align="center" color="#EA85C7" >Change Password</Link>
                            
                            <Link onClick={handleDisplayDeleteAccountConfirmMsg} sx={{ fontWeight: "600", m:"1vh" }} variant="h7" align="center" color="#D70040" >Delete Account</Link>
                    </Box>
                }
            {user.is_streamer && 
                <Box sx={{
                    display: {sm: "flex-column", md: "grid"},
                    gridTemplateColumns: "repeat(2, 1fr)",
                    // gridAutoRows: "minmax(0, 1fr)",
                    gap: "1rem",
                    width: "100%",
                }}>
                    
                    <Box sx={{
                        display: "flex",
                        alignItems: "start",
                        justifyContent: "space-between",
                        flexDirection: "column",
                        border: "1px solid #DCDCE4",
                        borderRadius: "32px",
                        padding: "1rem",
                        margin: "2vh",
                    }}>
                        <Typography sx={{ fontWeight: "600" }} variant="h6" align="center">Username</Typography>
                        <Typography sx={{ fontWeight: "600" }} variant="h6" align="center" color="#BDC1D6">@{user.username}</Typography>
                        
                        <Typography sx={{ fontWeight: "600" }} variant="h6" align="center">Email</Typography>
                        <Typography sx={{ fontWeight: "600" }} variant="h6" align="center" color="#BDC1D6">{user.email}</Typography>
                        
                        <Typography sx={{ fontWeight: "600" }} variant="h6" align="center">Date of Birth</Typography>
                        <Typography sx={{ fontWeight: "600" }} variant="h6" align="center" color="#BDC1D6">{formatDate(new Date(user.date_of_birth), "MM/dd/yyyy")}</Typography>
                        
                        <Link onClick={() => history.push("/forgot-password")} sx={{ fontWeight: "600", mt:"1vh" }} variant="h7" align="center" color="#EA85C7" >Change Password</Link>
                        <Link onClick={handleDisplayDeleteAccountConfirmMsg} sx={{ fontWeight: "600", mt:"1vh" }} variant="h7" align="center" color="#D70040" >Delete Account</Link>
                    </Box>
                    <Box sx={{
                        display: { xs: "none", sm: "flex" },
                        alignItems: "start",
                        flexDirection: "column",
                        flex: 1,
                        border: "1px solid #DCDCE4",
                        borderRadius: "32px",
                        padding: "1rem",
                        height: "256px",
                        margin: "2vh",
                    }}>
                        <Typography sx={{ fontWeight: "600" }} variant="h6" align="center">Affiliate Code</Typography>
                        <Typography sx={{ fontWeight: "600" }} variant="h6" align="center" color="#BDC1D6">@{user.username}</Typography>
                        
                        <Typography sx={{ fontWeight: "600" }} variant="h6" align="center">Stream Key</Typography>
                        <Typography sx={{ fontWeight: "600" }} variant="h6" align="center" color="#BDC1D6">{user.email}</Typography>
                        
                        <Typography sx={{ fontWeight: "600" }} variant="h6" align="center">Biography</Typography>
                        <Typography sx={{ fontWeight: "600" }} variant="h6" align="center" color="#BDC1D6">{formatDate(new Date(user.date_of_birth), "MM/dd/yyyy")}</Typography>
                        
                    </Box>
                </Box>
            }
                
            </Box>
        </Container>
        </>
    )
}

export default SettingsPage