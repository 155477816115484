import React, { useState, Fragment, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  TextField,
  Button,
  Box,
  FormControl,
  FormControlLabel,
  Checkbox,
  Typography,
  ImageList,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// imports for form validation
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

function RegisterForm() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [email, setEmail] = useState("");
  const [preferredName, setPreferredName] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState(new Date());
  const [isStreamer, setIsStreamer] = useState(false);
  const errors = useSelector((store) => store.errors);
  const dispatch = useDispatch();
  const history = useHistory();

  const registerUser = (event) => {
    event.preventDefault();
    // non streamers go straight to verification
    if (
      username &&
      password &&
      email &&
      preferredName &&
      dateOfBirth &&
      confirmPassword &&
      password === confirmPassword &&
      isStreamer === false
    ) {
      dispatch({
        type: "REGISTER",
        payload: {
          email: email,
          username: username,
          password: password,
          confirm_password: confirmPassword,
          date_of_birth: dateOfBirth,
          name: preferredName,
        },
        // history
      });
      // history.push({
      //   pathname: "/verification",
      //   state: {
      //     email: email,
      //     password: password,
      //   },
      // });
    }
    // streamers go to streamer application
    else if (
      username &&
      password &&
      email &&
      preferredName &&
      dateOfBirth &&
      confirmPassword &&
      password === confirmPassword &&
      isStreamer === true
    ) {
      dispatch({
        type: "REGISTER",
        payload: {
          email: email,
          username: username,
          password: password,
          confirm_password: confirmPassword,
          date_of_birth: dateOfBirth,
          name: preferredName,
        },
      });
      history.push({
        pathname: "/streamerapplication",
        state: {
          email: email,
          password: password,
        },
      });
    } else {
      dispatch({ type: "REGISTRATION_INPUT_ERROR" });
    }
  }; // end registerUser

  // form validation functionality
  const validationSchema = Yup.object().shape({
    preferredName: Yup.string().required("Preferred name is required"),
    username: Yup.string()
      .required("Username is required")
      .min(6, "Username must be at least 6 characters")
      .max(20, "Username must not exceed 20 characters"),
    email: Yup.string().required("Email is required").email("Email is invalid"),
    password: Yup.string()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters")
      .max(40, "Password must not exceed 40 characters"),
    confirmPassword: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("password"), null], "Confirm Password does not match"),
  });
  // more form validation
  const {
    register,
    control,
    handleSubmit,
    formState: { error },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  return (
    <>
      <form onSubmit={registerUser}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: {md: "100vh"},
            margin: "0 5vh",
          }}
        >
          <Box
            sx={{
              backgroundColor: "#fff",
              display: "flex",
              padding: "0.5rem",
              borderRadius: "0.5rem",
              gap: "0 2rem",
              position: "relative",
              overflow: "hidden",
              height: "fit-content",
              display: {sm: "flex-column", md: "flex", lg: "flex"}

            }}
          >
            <img
              style={{ position: "absolute", top: "0", left: "0" }}
              src="/assets/images/leafLeft.png"
              alt=""
            />
            {/* <img
              style={{ position: "absolute", bottom: "0", right: "0" }}
              src="/assets/images/leafRight.png" alt="" /> */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-around",
                gap: "0.625rem",
                padding: "1rem 1rem",
                height: "100%",
              }}
            >
              {errors.registrationMessage[0] && 
                <Box
                  sx={{
                    width: "50%",
                    textAlign: "center",
                    margin: "0.125rem auto",
                  }}
                  className="alert"
                  role="alert"
                >
                  {errors.registrationMessage}
                </Box>
              }
              <Box
                sx={{
                  objectFit: "cover",
                }}
              >
                <img src="/assets/images/logo.png" alt="" />
              </Box>
              <Box
                sx={{
                  objectFit: "cover",
                }}
              >
                <img src="/assets/images/coffee.png" alt="" />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                boxShadow:
                  "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)",
                width: "384px",
                padding: "1rem 1rem",
                zIndex: "1",
                backgroundColor: "#fff",
                width: "400px",
                borderRadius: "1rem",
              }}
            >
              <Box
                sx={{
                  textAlign: "center",
                }}
              >
                <h1 style={{ fontWeight: "bold", color: "#32324D" }}>
                  Welcome to Omi Live!
                </h1>
                <p style={{ color: "#8c8c8c" }}>
                  Sign up to watch livestreams featuring products from the
                  latest and greatest eco-friendly brands!
                </p>
              </Box>
              <Box
                sx={{
                  display: {sm: "flex-column", md: "grid"},
                  justifyContent: {sm: "space-around"},
                  alignItems: {sm: "center"},
                  gridTemplateColumns: "repeat(2, 1fr)",
                  gap: "8px",
                }}
              >
                <TextField
                  id="email"
                  name="email"
                  label="Email Address"
                  sx={{
                    marginBottom: "8px",
                    "& .MuiInputBase-root": {
                      backgroundColor: "#F7F7F7",
                      color: "#A2A0A9",
                    },
                  }}
                  type="email"
                  placeholder="customer@email.com"
                  required
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                  inputRef={{ ...register("email") }}
                />
                <TextField
                  id="username"
                  name="username"
                  label="Username"
                  sx={{
                    marginBottom: "8px",
                    "& .MuiInputBase-root": {
                      backgroundColor: "#F7F7F7",
                      color: "#A2A0A9",
                    },
                  }}
                  type="text"
                  placeholder="sampleUser00"
                  required
                  value={username}
                  onChange={(event) => {setUsername(event.target.value)}}
                  inputRef={{ ...register("username") }}
                />
                <TextField
                  id="preferredName"
                  name="preferredName"
                  label="Preferred Name"
                  sx={{
                    marginBottom: "8px",
                    "& .MuiInputBase-root": {
                      backgroundColor: "#F7F7F7",
                      color: "#A2A0A9",
                    },
                  }}
                  type="text"
                  placeholder="Jane Doe"
                  required
                  value={preferredName}
                  onChange={(event) => setPreferredName(event.target.value)}
                  inputRef={{ ...register("preferredName") }}
                />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    onChange={(date) => setDateOfBirth(date)}
                    label="Date of Birth"
                    slotProps={{
                      textField: {
                        required: true,
                      },
                    }}
                    disableFuture
                    sx={{
                      marginBottom: "8px",
                      "& .MuiInputBase-root": {
                        backgroundColor: "#f7f7f7",
                      },
                    }}
                  />
                </LocalizationProvider>
                <TextField
                  id="password"
                  name="password"
                  label="Password"
                  sx={{
                    marginBottom: "8px",
                    "& .MuiInputBase-root": {
                      backgroundColor: "#F7F7F7",
                      color: "#A2A0A9",
                    },
                  }}
                  type="password"
                  placeholder="password"
                  required
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                  inputRef={{ ...register("password") }}
                />
                <TextField
                  id="confirmPassword"
                  name="confirmPassword"
                  label="Confirm Password"
                  sx={{
                    "& .MuiInputBase-root": {
                      backgroundColor: "#F7F7F7",
                      color: "#A2A0A9",
                    },
                  }}
                  type="password"
                  placeholder="password"
                  required
                  onChange={(event) => setConfirmPassword(event.target.value)}
                  inputRef={{ ...register("confirmPassword") }}
                />
              </Box>
              <FormControlLabel
                sx={{
                  margin: 0,
                }}
                control={
                  <Checkbox
                    onChange={() => setIsStreamer(!isStreamer)}
                    color="primary"
                    size="small"
                  />
                }
                label="Join the Omi Live Streaming Affiliate Program"
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "2px",
                }}
              >
                <p>
                  Do you already have an account?
                  <button
                    style={{ marginLeft: "4px" }}
                    type="button"
                    className="btn btn_asLink"
                    onClick={() => {
                      history.push("/login");
                    }}
                  >
                    Sign in
                  </button>
                </p>
                <Button
                  type="submit"
                  sx={{
                    fontFamily: "Poppins",
                    marginBottom: "0.625rem",
                    width: "100%",
                  }}
                  onClick={() => handleSubmit(registerUser)}
                >
                  Sign Up
                </Button>
                <span style={{ color: "#8083A3" }}>Or</span>
                <ImageList
                  sx={{
                    display: "flex",
                    margin: "0",
                  }}
                >
                  <img
                    style={{ cursor: "pointer" }}
                    src="/assets/images/google.png"
                    alt=""
                    loading="lazy"
                  />
                  <img
                    style={{ cursor: "pointer" }}
                    src="/assets/images/fb.png"
                    alt=""
                    loading="lazy"
                  />
                  <img
                    style={{ cursor: "pointer" }}
                    src="/assets/images/twitter.png"
                    alt=""
                    loading="lazy"
                  />
                </ImageList>
              </Box>
            </Box>
          </Box>
        </Box>
      </form>
    </>
  );
}

export default RegisterForm;
