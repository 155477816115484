import { REHYDRATE } from 'redux-persist/lib/constants'; 


// need to update
const INIT_STATE = [
  {
    "id":"d055acba-87ff-11ee-9528-0242ac120002",
    "name":"EarthHero Hand Crochet Frog Dog Toy",
    "image_url":"https://cdn.shopify.com/s/files/1/0617/2878/4620/products/Ware-of-the-Dog-Hand-Crochet-Frog-Dog-Toy-1-1_5f6d4cf7-0261-4147-bc75-e0b5db53e626_800x.jpg?v=1671244545",
    "description":"Give your pup a fun and quirky plush friend with the Hand Crochet Frog Dog Toy from Ware of the Dog! This eco-friendly dog toy is handmade by craftswomen in Turkey and made with 100% organic cotton and safe, natural dyes. Adorable and totally shakeable, it's perfect for playtime!",
    "platform_coupon_code":"58249",
    "platform_coupon_expiration":"2023-05-08T15:00:00+00:00",
    "url":"https://earthhero.com/collections/ware-of-the-dog/products/ware-of-the-dog-hand-crochet-frog-dog-toy#",
    "order":4
  }
]

const searchResults = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "SET_SEARCH_RESULTS":
      return {...action.payload};
    case REHYDRATE:
      return {...state};
    default:
      return state;
  }
};

export default searchResults;

