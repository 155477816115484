import { legacy_createStore as createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import logger from "redux-logger";
import { persistStore, persistReducer, createTransform, createMigrate } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import localforage from 'localforage';
import hardSet from 'redux-persist/lib/stateReconciler/hardSet'

import rootReducer from "./reducers/_root.reducer"; // imports ./redux/reducers/index.js
import rootSaga from "./sagas/_root.saga"; // imports ./redux/sagas/index.js
import autoMergeLevel1 from "redux-persist/es/stateReconciler/autoMergeLevel1";
import JSOG from 'jsog'
import autoMergeLevel2 from "redux-persist/es/stateReconciler/autoMergeLevel2";

export const JSOGTransform = createTransform(
    (inboundState, key) => JSOG.encode(inboundState),
    (outboundState, key) => JSOG.decode(outboundState),
)
// export default store;
// export default () => {
const sagaMiddleware = createSagaMiddleware();

// this line creates an array of all of redux middleware you want to use
// we don't want a whole ton of console logs in our production code
// logger will only be added to your project if your in development mode
// const middlewareList = [sagaMiddleware];
const migrations = {
  0: state => {
    return {
      ...state,
      settings: {
        "aye": "fuck u",
      },
    };
  },
};
const middlewareList = process.env.NODE_ENV === "development"
  ? [sagaMiddleware, logger]
  : [sagaMiddleware];
const persistConfig = {
  key: 'root',
  storage: localforage,
  stateReconciler: hardSet,
  transforms: [JSOGTransform],
  migrate: createMigrate(migrations, { debug: false }),
  // must update version string when updating reducers initStates
  version: "2.25",
  // storage,
}
const persistedReducer = persistReducer(persistConfig, rootReducer)
const store = createStore(
  // tells the saga middleware to use the rootReducer
  // rootSaga contains all of our other reducers
  persistedReducer,
  // undefined,
  // adds all middleware to our project including saga and logger
  applyMiddleware(...middlewareList)
);
  
// tells the saga middleware to use the rootSaga
// rootSaga contains all of our other sagas
sagaMiddleware.run(rootSaga);
let persistor = persistStore(store)
// export store
export { store, persistor}

// }
