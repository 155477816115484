import React from 'react'
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { Box, Button, Container, Stack, Typography } from '@mui/material';
import { useHistory } from "react-router-dom";
import Navbar from "../Navbar/Navbar";

function LandingPage() {
  const history = useHistory();

  const onLogin = (event) => {
    history.push("/login");
  };
  return (
    <>
      <Container>
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", overflow: "hidden" }}>
          <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", gap: "8px", minWidth: "504px" }}>
            <Typography sx={{ fontWeight: "600", }} variant="h3" gutterBottom >An interactive and immersive shopping experience</Typography>
            <Typography variant="body1" gutterBottom >Welcome to Omi Live, if you're an eco-conscious Gen Z buyer but have difficulty finding REAL eco-friendly brands. This is no hidden place for green-washed brands, We encourage a 1% greener lifestyle through day-to-day shopping.</Typography>
            <Box>
              <Stack direction="row" spacing={2}>
                <Button onClick={onLogin} variant='contained' sx={{ padding: "1.5vh 5vh", fontSize: "2vh"   }}>Enter</Button>
                <Button 
                  variant='text' 
                  sx={{ color: "black", display: "flex", alignItems: "center", justifyContent: "center", }}>
                  <PlayCircleIcon />
                  Watch how we get it done
                </Button>
              </Stack>
            </Box>
          </Box>
          <Box
            component="img"
            sx={{
              height: "700px",
              objectFit: "cover",
              flex: 1,
            }}
            alt=""
            src="/assets/images/landing_new.png"
          />
        </Box>
      </Container>
    </>
  )
}

export default LandingPage