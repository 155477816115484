import { useState } from "react";
import {
  useTheme,
  Card,
  CardMedia,
  CardContent,
  Typography,
  CardActions,
  IconButton,
  Badge,
  Modal,
  Box,
  Button,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import WishlistButton from "../../WishlistButton/WishlistButton";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import StorefrontOutlinedIcon from "@mui/icons-material/StorefrontOutlined";
import CloseIcon from "@mui/icons-material/Close";
import ConfirmationPopup from "../../../ConfirmationPopup/ConfirmationPopup";
import { useDispatch, useSelector } from "react-redux";
import BuyButton from "../../BuyButton/BuyButton";
import EditIcon from "@mui/icons-material/Edit";
import TooltipMUI from "@mui/material/Tooltip";
import { Add, PlusOneRounded } from "@mui/icons-material";

function ProductListItem({ product, streamId, }) {
  const theme = useTheme();
  const history = useHistory();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const [displayConfirmBuy, setDisplayConfirmBuy] = useState(false);
  const [displayShareMsg, setDisplayShareMsg] = useState(false);
  const currentStream = useSelector((store) => store.currentStream);
  const currentProduct = useSelector((store) => store.currentProduct);
  const dayjs = require("dayjs");
  const handleBuy = () => {
    window.open(product.url, "_blank");
  };
  const user = useSelector((store) => store.user);

  const handleClickShare = () => {
    setDisplayShareMsg(true);
    navigator.clipboard.writeText(product.url);
  };

  // reserved for streamers only, viewers do not need to add products to stream
  const handleAddToStream = () => {
    dispatch({
      type: "ADD_PRODUCT_TO_STREAM",
      payload: { productID: product.id, streamID: streamId },
    });
    dispatch({
      type: "FETCH_STREAM_BY_ID",
      payload: { streamID: streamId },
    });
    history.push(`/edit-stream/${streamId}`);
  };

  /*   const handleGoToProduct = () => {
      history.push(`/product/${product.id}`);
    }; */

  return (
    <>
      {/* Share Button Popup */}
      {displayShareMsg && (
        <ConfirmationPopup
          setDisplayConfirmation={setDisplayShareMsg}
          handleConfirm={handleBuy}
          alertText={`Link to vendor's website copied to clipboard. Continue
        to vendor site to purchase?`}
          hidePopupText="KEEP SHOPPING"
          confirmPopupText="CONTINUE TO SITE"
          top="38vh"
        />
      )}
      {/* Buy Button Popup */}
      {displayConfirmBuy && (
        <ConfirmationPopup
          setDisplayConfirmation={setDisplayConfirmBuy}
          handleConfirm={handleBuy}
          alertText={`Discount code ${product.platform_coupon_code} copied to clipboard. Continue
        to vendor site to purchase?`}
          hidePopupText="KEEP SHOPPING"
          confirmPopupText="CONTINUE"
          top="38vh"
        />
      )}
      {/* Modal Start */}
      <Modal
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiBackdrop-root": {
            bgcolor: "rgba(0,0,0,0.1)",
          },
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "50vw",
            height: "fit-content",
            bgcolor: "#fff",
            borderRadius: "5px",
            boxShadow: "0 1px 2px 0 rgb(0 0 0 / 0.05)",
            padding: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "0 32px",
            }}
          >
            <IconButton
              sx={{ position: "absolute", top: 12, right: 12 }}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
            <Box
              component="img"
              sx={{
                display: { xs: "none", xl: "block" },
                width: "90%",
                height: "288px",
                objectFit: "contain",
                borderRadius: "5px",
              }}
              alt=""
              src={product.image_url}
              onError={({ currentTarget }) => {
                console.log("loop man")
                currentTarget.onerror = null; // prevents looping
                currentTarget.src="assets/images/download.png";
              }}
            />
            <Box>
              <Typography noWrap gutterBottom variant="h6" component="div" sx={{ fontWeight: "600" }}>{product.name}</Typography>
              <Typography variant="body1" gutterBottom sx={{ fontWeight: "600" }}>Description</Typography>
              <Typography variant="body2" color="text.secondary" sx={{ textAlign: "justify" }}>{product.description}</Typography>
              <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", margin:"2% 0" }}>
                <Box sx={{ display: "flex", justifyContent: "flex-start", gap: "0 8%"}}>
                  <Box sx={{ display: "flex", flexDirection: "column", gap: "0 8px" }}>
                    <Typography variant="body1" gutterBottom component="div" sx={{ fontWeight: "600" }}>Merchant</Typography>
                    <Typography variant="body2" color="text.secondary" gutterBottom component="div">Adidas</Typography>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column", gap: "0 8px" }}>
                    <Typography variant="body1" gutterBottom component="div" sx={{ fontWeight: "600" }}>Categories</Typography>

                    <Typography variant="body2" color="text.secondary" gutterBottom component="div">
                      <Button size="small" variant="outlined" sx={{ borderRadius: "32px", fontSize: "8px", color: "black" }}> {product.category != null ? product.category : "100% recycled"}</Button>
                      {/* {product.category} */}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                <Box sx={{ display: "flex", justifyContent: "flex-start", gap: "0 5%" }}>
                  <Box sx={{ display: "flex", flexDirection: "column", gap: "0 8px" }}>
                    <Typography variant="body1" gutterBottom component="div" sx={{ fontWeight: "600" }}>Promo Code</Typography>
                    <Typography variant="body2" color="text.secondary" gutterBottom component="div" sx={{fontStyle: "italic"}}>{product.platform_coupon_code}</Typography>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column", gap: "0 8px" }}>
                    <Typography variant="body1" gutterBottom component="div" sx={{ fontWeight: "600" }}>Expiration Date</Typography>
                    <Typography variant="body2" color="text.secondary" gutterBottom component="div">{dayjs(product.platform_coupon_expiration).format("dddd, MMMM D, YYYY")}</Typography>
                  </Box>
                </Box>
                <Box onClick={handleClose} textAlign='center' sx={{margin:"2% 0 0 0"}}>
                  <BuyButton setDisplayConfirmBuy={setDisplayConfirmBuy} />
                </Box>
              </Box>
            </Box>
            
          </Box>
        </Box>
      </Modal>
      {/* Modal End */}

      <Card key={product.id} sx={{ maxWidth: 380, borderRadius: "5px" }}>
        <CardMedia
          component="img"
          height="140"
          image={product.image_url}
          onError={({ currentTarget }) => {
            console.log("loop man")
            currentTarget.onerror = null; // prevents looping
            currentTarget.src="assets/images/download.png";
          }}
          alt="product"
        />
        <CardContent sx={{ textAlign: "justify" }}>
          <Typography noWrap gutterBottom variant="h6" component="div">
            {product.name}
          </Typography>
          <Typography
            sx={{
              display: { xs: "none", lg: "-webkit-box" },
              overflow: "hidden",
              textOverflow: "ellipsis",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
            }}
            variant="body2"
            color="text.secondary"
          >
            <TooltipMUI title={product.description}>
              {product.description}
            </TooltipMUI>
          </Typography>
        </CardContent>
        <CardActions disableSpacing>
          { streamId != null ?
          // only show the add button to add to stream
            <IconButton
            onClick={handleAddToStream}
            color="primary"
            sx={{ marginLeft: "auto" }}
          >
            <Add fontSize="large" />
          </IconButton> :
          // show favorite, shop and share buttons if not adding to stream 
          <>
          <IconButton>
            <Badge color="primary" sx={{ transform: 'translate(36px, -16px)' }} />
            <WishlistButton
              type="icon"
              product={product}
              envType="product-list"
            />
          </IconButton>
          <IconButton color="primary">
            <Badge badgeContent={parseFloat(product.views)} max={999} color="primary" sx={{ transform: 'translate(36px, -16px)' }} />
            <ShareOutlinedIcon onClick={handleClickShare} setDisplayShareMsg={setDisplayShareMsg}/>
          </IconButton>
          {user.is_admin &&
            <IconButton onClick={() => history.push(`/productform/${product.id}`)} color="primary">
              <EditIcon />
            </IconButton>
          }
          <IconButton onClick={handleOpen} color="primary" sx={{ marginLeft: "auto" }}>
            <StorefrontOutlinedIcon fontSize="large" />
          </IconButton>
          </>
        }
          
        </CardActions>
      </Card>
    </>
  );
}

export default ProductListItem;
