import React, { useState } from "react";
import { TextField, Button, Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';

function StreamerApplication() {
    const [twitterUsername, setTwitterUsername] = useState("");
    const [instagramUsername, setInstagramUsername] = useState("");
    const [tiktokUsername, setTiktokUsername] = useState("");
    const [twitterFollowers, setTwitterFollowers] = useState(0);
    const [instagramFollowers, setInstagramFollowers] = useState(0);
    const [tiktokFollowers, setTiktokFollowers] = useState(0);
    const errors = useSelector((store) => store.errors);
    const dispatch = useDispatch();
    const history = useHistory();

    const registerStreamer = (event) => {
        event.preventDefault();
        // allow submit if all fields have value
        if (twitterUsername, instagramUsername, tiktokUsername, twitterFollowers, instagramFollowers, tiktokFollowers) {
            dispatch({
                type: "REGISTER",
                payload: {
                    twitterUsername: twitterUsername,
                    instagramUsername: instagramUsername,
                    tiktokUsername: tiktokUsername,
                    twitterFollowers: twitterFollowers,
                    instagramFollowers: instagramFollowers,
                    tiktokFollowers: tiktokFollowers
                },
            });
            history.push({
                pathname: "/verification",
            })
        }
        else {
            dispatch({ type: 'REGISTRATION_INPUT_ERROR' });
        }
    }; // end registerStreamer

    return (
        <>
            <form onSubmit={registerStreamer}>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100vh",
                    }}
                >
                    <Box sx={{
                        backgroundColor: "#fff",
                        display: "flex",
                        padding: "4rem 2rem",
                        borderRadius: "0.5rem",
                        gap: "0 2rem",
                        position: "relative",
                        overflow: "hidden",
                        height: "fit-content"
                    }}>
                        <img
                            style={{ position: "absolute", top: "0", left: "0" }}
                            src="/assets/images/leafLeft.png" alt="" />
                        <img
                            style={{ position: "absolute", bottom: "0", right: "0" }}
                            src="/assets/images/leafRight.png" alt="" />
                        <Box sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "space-around",
                            gap: "0.625rem",
                            padding: "1rem 1rem",
                            height: "100%"
                        }}>
                            {errors.registrationMessage && (
                                <Box
                                    sx={{ width: "50%", textAlign: "center", margin: "0.125rem auto" }}
                                    className="alert"
                                    role="alert"
                                >
                                    {errors.registrationMessage}
                                </Box>
                            )}
                            <Box sx={{
                                objectFit: "cover"
                            }}>
                                <img src="/assets/images/logo.png" alt="" />
                            </Box>
                            <Box sx={{
                                objectFit: "cover"
                            }}>
                                <img src="/assets/images/coffee.png" alt="" />
                            </Box>
                        </Box>
                        <Box sx={{
                            display: "flex",
                            flexDirection: "column",
                            boxShadow: "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)",
                            width: "384px",
                            padding: "1rem 1rem",
                            zIndex: "1",
                            backgroundColor: "#fff",
                            width: "420px",
                            borderRadius: "1rem",
                        }}>
                            <Box
                                sx={{
                                    textAlign: "center"
                                }}>
                                <h2 style={{ fontWeight: "bold", color: "#32324D" }}>Omi Live Streaming Program</h2>
                                <p style={{ color: "#8c8c8c", fontSize: "14px" }}>This is an exclusive opportunity to work with the
                                    latest and greatest eco-friendly brands! You will promote
                                    products and make 10% commission on each sale!</p>
                            </Box>
                            <Box sx={{
                                display: "grid",
                                gridTemplateColumns: "repeat(2, 1fr)",
                                gap: "8px",
                            }}>
                                <TextField
                                    label="X (Twitter Username)"
                                    sx={{
                                        marginBottom: "8px",
                                        '& .MuiInputBase-root': {
                                            backgroundColor: '#F7F7F7',
                                            color: '#A2A0A9'
                                        },
                                    }}
                                    type="text"
                                    placeholder="@musk22"
                                    required
                                    value={twitterUsername}
                                    onChange={(event) => setTwitterUsername(event.target.value)}
                                />
                                <TextField
                                    label="Number of Followers"
                                    sx={{
                                        marginBottom: "8px",
                                        '& .MuiInputBase-root': {
                                            backgroundColor: '#F7F7F7',
                                            color: '#A2A0A9'
                                        },
                                    }}
                                    type="number"
                                    inputProps={{ min: 1, max: 90000000 }}
                                    placeholder="5,134"
                                    required
                                    value={twitterFollowers}
                                    onChange={(event) => setTwitterFollowers(event.target.value)}
                                />
                                <TextField
                                    label="Instagram Username"
                                    sx={{
                                        marginBottom: "8px",
                                        '& .MuiInputBase-root': {
                                            backgroundColor: '#F7F7F7',
                                            color: '#A2A0A9'
                                        },
                                    }}
                                    type="text"
                                    placeholder="@rossulbricht"
                                    required
                                    value={instagramUsername}
                                    onChange={(event) => setInstagramUsername(event.target.value)}
                                />

                                <TextField
                                    label="Number of Followers"
                                    sx={{
                                        marginBottom: "8px",
                                        '& .MuiInputBase-root': {
                                            backgroundColor: '#F7F7F7',
                                            color: '#A2A0A9'
                                        },
                                    }}
                                    type="number"
                                    inputProps={{ min: 1, max: 90000000 }}
                                    placeholder="20,458"
                                    required
                                    value={instagramFollowers}
                                    onChange={(event) => setInstagramFollowers(event.target.value)}
                                />
                                <TextField
                                    label="Tiktok Username"
                                    sx={{
                                        marginBottom: "8px",
                                        '& .MuiInputBase-root': {
                                            backgroundColor: '#F7F7F7',
                                            color: '#A2A0A9'
                                        },
                                    }}
                                    type="text"
                                    placeholder="@rossulbricht"
                                    required
                                    value={tiktokUsername}
                                    onChange={(event) => setTiktokUsername(event.target.value)}
                                />
                                <TextField
                                    label="Number of Followers"
                                    sx={{
                                        marginBottom: "8px",
                                        '& .MuiInputBase-root': {
                                            backgroundColor: '#F7F7F7',
                                            color: '#A2A0A9'
                                        },
                                    }}
                                    type="number"
                                    inputProps={{ min: 1, max: 90000000 }}
                                    placeholder="220,582"
                                    required
                                    value={tiktokFollowers}
                                    onChange={(event) => setTiktokFollowers(event.target.value)}
                                />
                            </Box>
                            <Box sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: "2px",
                                padding: "2rem 0"
                            }}>
                                <Button sx={{
                                    fontFamily: "Poppins",
                                    width: "100%"
                                }} type="submit">
                                    Submit Application
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </form>
        </>
    )
}

export default StreamerApplication