import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Box, Button, useTheme, Grid, Stack, Chip } from "@mui/material";
import EditStreamInfo from "../EditStreamInfo/EditStreamInfo";
import dayjs from "dayjs";
import {
  DateCalendar,
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Modal,
} from "@mui/material";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import {
  ArrowDropDown,
  ArrowDropUp,
  Delete,
  Edit,
  NewReleases,
  NoteAdd,
} from "@mui/icons-material";
// import dayjs from 'dayjs';
import ConfirmationPopup from "../../ConfirmationPopup/ConfirmationPopup";

function EditStream() {
  const { streamID } = useParams();
  const currentStream = useSelector((store) => store.currentStream);
  const user = useSelector((store) => store.user);
  const products = useSelector((store) => store.allProducts);
  const theme = useTheme();
  const dispatch = useDispatch();
  const history = useHistory();
  const [newTitle, setNewTitle] = useState(currentStream?.title);
  const [newDescription, setNewDescription] = useState(currentStream?.description);
  const [edit, setEdit] = useState(false);
  const [date, setDate] = useState(dayjs(currentStream?.scheduled));
  const [newStream, setNewStream] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [displayEditInfo, setDisplayEditInfo] = useState(false);
  const [displayConfirmRemoveFromStream, setDisplayConfirmRemoveFromStream] =
    useState(false);
  const [displayConfirmGoLive, setDisplayConfirmGoLive] = useState(false);
  const [displayConfirmDeleteStream, setDisplayConfirmDeleteStream] =
    useState(false);
  const [productToRemove, setProductToRemove] = useState();
  const streamKey = `?key=${user.stream_key}&user=${user.username}&streamID=${streamID}`;
  const [deleteStreamOpen, setDeleteStreamOpen] = useState(false);
  const [deleteProductOpen, setDeleteProductOpen] = useState(false);
  const [goLiveOpen, setGoLiveOpen] = useState(false);
  const [copied, setCopied] = useState(false);
  const copyKey = () => {
    navigator.clipboard.writeText(streamKey);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  // const date = dayjs(currentStream?.scheduled);

  // useEffect(() => {
  //   console.log("API URL: ", currentStream);
  // });

  // useEffect(() => {
  //   setNewTitle(currentStream?.title);
  // }, [newTitle]);

  const handleOrderIncrease = (product) => {
    console.log("akshitIncrease", product);
    dispatch({
      type: "ORDER_CHANGE",
      payload: {
        productID: product.id,
        order: product.order,
        currentStream: currentStream,
        type: "increase",
      },
    });
    console.log("akshitIncrease1", product);
  };

  const handleOrderDecrease = (product) => {
    console.log("akshitDecrease", product);
    dispatch({
      type: "ORDER_CHANGE",
      payload: {
        productID: product.id,
        order: product.order,
        currentStream: currentStream,
        type: "decrease",
      },
    });
    console.log("akshitDecrease1", product);
  };
  const removeFromStream = (product) => {
    dispatch({
      type: "REMOVE_PRODUCT_FROM_STREAM",
      payload: { streamID: currentStream.id, productID: product.id },
    });
  };

  const startStream = () => {
    // TODO: begin stream via OBS
    dispatch({ type: "START_STREAM", payload: streamID });
    history.push(`/streamer-stream/${streamID}`);
  };

  const deleteStream = () => {
    // TODO: end stream for all users
    dispatch({ type: "DELETE_STREAM", payload: streamID });
    history.push("/home");
  };

  // const handleCancel = () => {
  //   dispatch({ type: "DELETE_STREAM", payload: currentStream.id });
  //   history.push("/home");
  // };

  const handleSubmit = () => {
    dispatch({
      type: "UPDATE_STREAM_INFO",
      payload: {
        id: currentStream.id,
        title: newTitle,
        description: newDescription,
        scheduled: date,
      },
    });
  };
  const data = currentStream?.products;
  // const productData = [
  //   {
  //     id: 15,
  //     name: "Dazed but Amazed Complete Bed Set",
  //     image_url:
  //       "https://cdn.shopify.com/s/files/1/0854/3658/products/IMG_0511_1296x.jpg?v=1629516627",
  //     description:
  //       "Everything you need to make your bed wrapped in our linens.\nGOTS certified 100% organic linen.",
  //     coupon_code: "41039",
  //     coupon_expiration: "2023-05-29T15:00:00",
  //     url: "https://dazedbutamazed.com/en-us/collections/tonal-curated-sets/products/natural-linen-full-bed-set",
  //     order: 3,
  //   },
  //   {
  //     id: 14,
  //     name: "Cozy Earth Bamboo Pillowcases",
  //     image_url:
  //       "https://cdn.shopify.com/s/files/1/2114/3697/products/2_1200x1200.jpg?v=1669057847",
  //     description:
  //       "Our ultra-soft Bamboo Pillowcases are made from 100% bamboo viscose and come in a set of two. Woven from naturally breathable and lightweight fabric, our pillowcases will keep you cool throughout the night and last for years to come.",
  //     coupon_code: "582931",
  //     coupon_expiration: "2023-04-25T15:00:00",
  //     url: "https://cozyearth.com/products/pillow-case-set?variant=40965705531572",
  //     order: 4,
  //   },
  //   {
  //     id: 18,
  //     name: "hello world",
  //     image_url:
  //       "https://cdn.shopify.com/s/files/1/0605/8488/6478/products/CAJAS_NOLE_NUEVAS_0710_1728x.jpg?v=1645803244",
  //     description: "Hello World!!!!!",
  //     coupon_code: "123",
  //     coupon_expiration: "2023-10-13T15:00:00",
  //     url: "https://nolecare.com/collections/all/products/bundle-shampoo-conditioner-horsetail",
  //     order: 3,
  //   },
  //   {
  //     id: 19,
  //     name: "hello wor",
  //     image_url:
  //       "https://cdn.shopify.com/s/files/1/0605/8488/6478/products/CAJAS_NOLE_NUEVAS_0710_1728x.jpg?v=1645803244",
  //     description: "Hello World!!!!!",
  //     coupon_code: "123",
  //     coupon_expiration: "2023-10-13T15:00:00",
  //     url: "https://nolecare.com/collections/all/products/bundle-shampoo-conditioner-horsetail",
  //     order: 2,
  //   },
  //   {
  //     id: 19,
  //     name: "hello wor",
  //     image_url:
  //       "https://cdn.shopify.com/s/files/1/0605/8488/6478/products/CAJAS_NOLE_NUEVAS_0710_1728x.jpg?v=1645803244",
  //     description: "Hello World!!!!!",
  //     coupon_code: "123",
  //     coupon_expiration: "2023-10-13T15:00:00",
  //     url: "https://nolecare.com/collections/all/products/bundle-shampoo-conditioner-horsetail",
  //     order: 2,
  //   },
  //   {
  //     id: 19,
  //     name: "hello wor",
  //     image_url:
  //       "https://cdn.shopify.com/s/files/1/0605/8488/6478/products/CAJAS_NOLE_NUEVAS_0710_1728x.jpg?v=1645803244",
  //     description: "Hello World!!!!!",
  //     coupon_code: "123",
  //     coupon_expiration: "2023-10-13T15:00:00",
  //     url: "https://nolecare.com/collections/all/products/bundle-shampoo-conditioner-horsetail",
  //     order: 2,
  //   },
  //   {
  //     id: 19,
  //     name: "hello wor",
  //     image_url:
  //       "https://cdn.shopify.com/s/files/1/0605/8488/6478/products/CAJAS_NOLE_NUEVAS_0710_1728x.jpg?v=1645803244",
  //     description: "Hello World!!!!!",
  //     coupon_code: "123",
  //     coupon_expiration: "2023-10-13T15:00:00",
  //     url: "https://nolecare.com/collections/all/products/bundle-shampoo-conditioner-horsetail",
  //     order: 2,
  //   },
  // ];
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "95%",
        minHeight: "80vh",
        backgroundColor: "#BADAD9",
      }}
    >
      <span
        style={{
          paddingLeft: 32,
          paddingTop: 32,
          fontSize: 32,
          fontWeight: 600,
          width: "100%",
          textAlign: "center",
          gap: 20,
          display: "flex",
        }}
      >
        {edit ? (
          <input
            placeholder={currentStream?.title}
            onChange={(e) => {
              setNewTitle(e.target.value);
            }}
          />
        ) : (
          <span>{currentStream?.title}</span>
        )}

        <div onClick={() => setEdit(!edit)} style={{ cursor: "pointer" }}>
          <Edit />
        </div>
      </span>
      <div
        id="content wrapper"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "32px",
          minHeight: "100%",
          width: "100%",
        }}
      >
        <div
          id="content"
          style={{
            borderRadius: "16px",
            backgroundColor: "white",
            minHeight: "100%",
            width: "100%",
            padding: 20,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            id="topContent"
            style={{
              display: "flex",
              alignItems: "flex-start",
              width: "100%",
              // height: 200,
              // backgroundColor: "green",
            }}
          >
            {/* Description textfield */}
            <div
              id="topLeft"
              style={{
                width: "50%",
                height: "100%",
                padding: 10,
                marginRight: 10,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <span style={{ fontWeight: 600, fontSize: 22, marginBottom: 10 }}>
              Description
              </span>
              <textarea
                style={{ padding: 10, borderWidth: 1, height: "17%", borderRadius: 4, borderColor: "#C0C0C0" }}
                placeholder={currentStream?.description}
                onChange={(e) => setNewDescription(e.target.value)}
              />
            </div>
            {/* Date Picker */}
            <div
              id="topRight"
              style={{
                width: "50%",
                padding: 10,
                marginRight: 10,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <span style={{ fontWeight: 600, fontSize: 22, marginBottom: 10 }}>
                Date
              </span>
              {/* <Box sx={{ display: "flex", flexDirection: "column" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateCalendar onChange={(date) => setDate(date)} />
                </LocalizationProvider>
                <Stack
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                  spacing={2}
                  direction="row"
                >
                  <Button onClick={() => setDisplayEditStream(false)}>
                    Cancel
                  </Button>
                  <Button color="warning" onClick={() => handlePlanNewStream()}>
                    Next
                  </Button>
                </Stack>
              </Box> */}
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker defaultValue={date} onChange={(date) => setDate(date)} />
              </LocalizationProvider>
            </div>
          </div>
          <div
            id="bottomContent"
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              // paddingTop: 20,
              // paddingBottom: 20,
            }}
          >
            {/* Showcased products table */}
            <div
              id="topLeft"
              style={{
                width: "50%",
                height: "100%",
                padding: 10,
                marginRight: 10,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <span style={{ fontWeight: 600, fontSize: 22, marginBottom: 10 }}>
              Showcased Products
              </span>
            </div>
            <div style={{ width: "100%" }}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow sx={{ fontWeight: 800}}>
                      <TableCell style={{color: "#B3B3B3", fontSize: "1vw",}}>Item Name</TableCell>
                      <TableCell style={{color: "#B3B3B3", fontSize: "1vw",}}>Vendor</TableCell>
                      <TableCell style={{color: "#B3B3B3", fontSize: "1vw",}}>Categories</TableCell>
                      {data?.length > 1 && <TableCell style={{color: "#B3B3B3", fontSize: "1vw",}}>Order</TableCell>}
                      <TableCell style={{color: "#B3B3B3", fontSize: "1vw",}}></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.sort((a, b) => a.order - b.order).map((row, index) => (
                      <TableRow key={row.id}>
                        <TableCell>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <img
                              src={row.image_url}
                              alt={row.name}
                              style={{
                                width: 100,
                                height: 50,
                                marginRight: 35,
                              }}
                            />
                            {row.name}
                          </div>
                        </TableCell>
                        <TableCell>{row.vendor_name != null ? row.vendor_name: "Vendor Name"}</TableCell>
                        <TableCell>
                          {/* {row.categories.map((category, index) => ( */}
                            {/* <Chip
                              key={index}
                              label={row.category}
                              variant="outlined"
                              style={{ margin: "2px" }}
                            /> */}
                            <Button size="small" variant="outlined" sx={{ borderRadius: "32px", fontSize: "8px", color: "black" }}> {row.category != null ? row.category : "100% recycled"}</Button>
                          {/* ))} */}
                        </TableCell>
                        {data?.length > 1 && <TableCell>
                          {/* {data?.length > 1 && ( */}
                          <>
                          {/* dont show "^" for first index */}
                            {index != 0 && 
                              <div>
                                <Button
                                  // variant="outlined"
                                  color="white"
                                  onClick={() => {
                                    handleOrderIncrease(row);
                                    // console.log("orderIncrease");
                                  }}
                                >
                                  <ArrowDropUp style={{ color: "black" }} />
                                </Button>
                              </div>
                            }
                            <div style={{height:"10px"}}>
                            </div>
                            {/* dont show "^" (downwards) for first index */}
                            {index != data.length-1 && 
                              <div>
                                <Button
                                  // variant="outlined"
                                  color="white"
                                  onClick={() => {
                                    handleOrderDecrease(row);
                                    // console.log("orderDecrease");
                                  }}
                                >
                                  <ArrowDropDown style={{ color: "black" }} />
                                </Button>
                              </div>
                            }
                          </>
                          {/* )} */}
                        </TableCell>}
                        <TableCell>
                          {/* <Button
                            // variant="outlined"
                            color="secondary"
                            sx={{
                              borderColor: "black",
                              "&:hover": { borderColor: "red" },
                            }}
                            >
                          </Button> */}
                            <Delete
                              onClick={() => removeFromStream(row)}
                              sx={{
                                color: "black",
                                "&:hover": { color: "red" },
                              }}
                            />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            {/* Bottom Buttons */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                justifyContent: "space-between",
                position: "relative",
                marginTop: 30,
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 10,
                }}
              >
                <Stack
                sx={{ display: "flex", justifyContent: "flex-end" }}
                spacing={2}
                direction="row">
                  <Button color="warning" onClick={() => setDeleteStreamOpen(true)}>
                    Delete
                  </Button>
                  <Button onClick={handleSubmit}> 
                    Save
                  </Button>
                </Stack>
              </div>
              
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 10,
                }}>
                <Stack
                sx={{ display: "flex", justifyContent: "flex-end" }}
                spacing={2}
                direction="row">
                <Button onClick={copied ? null : copyKey}> 
                  {copied ? "Copied!" : "Copy Stream Key"}
                </Button>
                <Button color="warning" onClick={() => setGoLiveOpen(true)}>
                  GO LIVE!
                </Button>
              </Stack>
              </div>
              <div
                style={{
                  position: "absolute",
                  right: 10,
                  marginBottom: 100,
                  cursor: "pointer",
                }}
                onClick={() => setOpenModal(true)}
              >
                <AddCircleOutlineRoundedIcon
                  fontSize="large"
                  background="primary"
                  color="secondary"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100vw",
          height: "100vh",
        }}
        open={openModal}
        onClose={() => setOpenModal(false)}
      >
        <div
          style={{
            padding: 50,
            borderRadius: 10,
            backgroundColor: "#CBE5DF",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <span style={{ fontWeight: 800, color: "green" }}>Add Product</span>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 25,
              marginTop: 30,
            }}
          >
            <div
              style={{
                padding: 25,
                backgroundColor: "#EA85C7",
                color: "white",
                fontWeight: 600,
                borderRadius: 10,
                cursor: "pointer",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 8,
              }}
              onClick={() => history.push(`/add-existing-product/${streamID}`)}
            >
              <NoteAdd />
              Existing
            </div>
            <div
              style={{
                padding: 25,
                backgroundColor: "#EA85C7",
                color: "white",
                fontWeight: 600,
                borderRadius: 10,
                cursor: "pointer",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 8,
              }}
              onClick={() => history.push(`/productform/${streamID}`)}
            >
              <NewReleases />
              Add New
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100vw",
          height: "100vh",
        }}
        open={goLiveOpen}
        onClose={() => setGoLiveOpen(false)}
      >
        <div
          style={{
            padding: 50,
            backgroundColor: "white",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <span>
            Are you sure you want to <b>Go Live?</b>
          </span>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: 20,
              gap: 20,
            }}
          >
            <Button
              onClick={() => setGoLiveOpen(false)}
              variant="outlined"
              sx={{ color: "#0f536a" }}
            >
              No
            </Button>
            <Button onClick={startStream}>Yes</Button>
          </div>
        </div>
      </Modal>
      <Modal
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100vw",
          height: "100vh",
        }}
        open={deleteStreamOpen}
        onClose={() => setDeleteStreamOpen(false)}
      >
        <div
          style={{
            padding: 50,
            backgroundColor: "white",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <span>Are you sure you want to delete this stream?</span>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: 20,
              gap: 20,
            }}
          >
            <Button
              onClick={() => setDeleteStreamOpen(false)}
              variant="outlined"
              sx={{ color: "#0f536a" }}
            >
              No
            </Button>
            <Button onClick={deleteStream}>Yes</Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default EditStream;
