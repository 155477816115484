import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";

import StreamerProductsList from "../StreamerProductsList/StreamerProductsList";
import StreamsList from "../StreamsList/StreamsList";
import { Box, Button, Typography, Container, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Switch } from '@mui/material'
import VideocamIcon from '@mui/icons-material/Videocam';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import CropFreeIcon from '@mui/icons-material/CropFree';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import TextField from '@mui/material/TextField';
import { DateCalendar, LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Stack from '@mui/material/Stack';
import dayjs from 'dayjs';
import TooltipMUI from '@mui/material/Tooltip';
import TableSortLabel from '@mui/material/TableSortLabel';

// linechart & barchart imports. Docs: https://recharts.org/en-US/examples | Docs: https://recharts.org/en-US/examples/SimpleBarChart
import {
  LineChart,
  Line,
  BarChart,
  Bar,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Legend,
  Rectangle,
} from "recharts";
import { DataGrid } from "@mui/x-data-grid";

function StreamerHomePage({ children }) {
  const { view } = useParams();
  const [display, setDisplay] = useState(
    view ? view : "streams");
  const user = useSelector((store) => store.user);
  const [displayEditStream, setDisplayEditStream] = useState(false)
  const [date, setDate] = useState(new Date());
  const dispatch = useDispatch();
  let streams = useSelector((store) => store.streams.streams);
  streams = streams.filter((streams) => 
  streams.streamer_username == user.username)

  console.log(streams)
  const componentBgdColor = "rgb(240, 240, 240, 0.9)"
  const [muted, setMuted] = useState(true);
  const toggleMute = () => {
    setMuted(!muted);
  };
  useEffect(() => {
    dispatch({ type: "FETCH_STREAMS" });
  }, []);

  const history = useHistory();

  // when the user clicks on a stream, go to the stream watch view
  const handleStreamClick = (stream) => {
    dispatch({ type: "SET_CURRENT_STREAM", payload: stream });
    // need to update backend to post streams to /username again (MEDIA SERVER)
    history.push(`/live/${stream.streamer_username}`);
  };

  // when the user clicks on a stream, go to the edit stream view
  const handleEditStream = (stream) => {
    dispatch({ type: "SET_CURRENT_STREAM", payload: stream });
    history.push(`/edit-stream/${stream.id}`);
  };

  const handlePlanNewStream = () => {
    // TODO: post new stream to the database and redirect to edit stream
    dispatch({ type: "POST_EMPTY_STREAM", payload: { history } });
  };

  // MUI switch functionality and state management
  const [toggled, setToggled] = useState(true);
  const handleChange = () => {
    setToggled(!toggled);
  };
  // refresh functionality
  const [seed, setSeed] = useState(1);
  const handleRefresh = () => {
    setSeed(Math.random())
  }
  // functionality for MUI table
  function createData(streamName, streamDate, streamProducts, streamCategories) {
    return { streamName, streamDate, streamProducts, streamCategories };
  }

  // recharts data
  const chartData = [
    {
      name: "Jan",
      viewers: 4000,
      watched: 2400,
      sold: 2400,
    },
    {
      name: "Feb",
      viewers: 3000,
      watched: 1398,
      sold: 1398,
    },
    {
      name: "Mar",
      viewers: 2000,
      watched: 1490,
      sold: 3800,
    },
    {
      name: "Apr",
      viewers: 2780,
      watched: 3908,
      sold: 3908,
    },
    {
      name: "May",
      viewers: 1890,
      watched: 1100,
      sold: 4800,
    },
    {
      name: "Jun",
      viewers: 2390,
      watched: 1500,
      sold: 3800,
    },
    {
      name: "Jul",
      viewers: 3390,
      watched: 1800,
      sold: 4200,
    },
    {
      name: "Aug",
      viewers: 3290,
      watched: 2500,
      sold: 4100,
    },
    {
      name: "Sept",
      viewers: 3190,
      watched: 2100,
      sold: 4000,
    },
    {
      name: "Oct",
      viewers: 3490,
      watched: 2000,
      sold: 4300,
    },
    {
      name: "Nov",
      viewers: 2990,
      watched: 3900,
      sold: 3900,
    },
    {
      name: "Dec",
      viewers: 2890,
      watched: 3700,
      sold: 3700,
    },
  ];
  const barData = [
    {
      name: 'Viewers',
      viewers: 4000,
    },
    {
      name: 'Watched',
      watched: 1398,
    },
    {
      name: 'Sold',
      sold: 2290,
    },
  ];

  // image carousel variables
  const blurredStreamsCount = 3;

  // image carousel functionality and state management
  const Carousel = ({ children }) => {
    const [active, setActive] = useState(2);
    const count = React.Children.count(children);

    return (
      <div style={{
        position: "relative",
        height: "16rem",
        width: "28rem",
        perspective: "500px",
        transformStyle: "preserve-3d",
      }}>
        {active > 0 &&
          <button style={{
            color: "white",
            fontSize: "5rem",
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            top: "50%",
            zIndex: "2",
            cursor: "pointer",
            userSelect: "none",
            background: "unset",
            border: "unset",
            transform: "translateX(-100%) translatey(-50%)"
          }}
            onClick={() => setActive(i => i - 1)}>
            <ChevronLeftIcon fontSize='large' />
          </button>}
        {React.Children.map(children, (child, i) => (
          <div style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            transform: "rotateY(calc(var(--offset) * 50deg)) scaleY(calc(1 + var(--abs-offset) * -0.4)) translateZ(calc(var(--abs-offset) * -30rem)) translateX(calc(var(--offset) / var(--abs-offset) * -10rem))",
            filter: "blur(calc(var(--abs-offset) * 1rem))",
            transition: "all 0.3s ease-out",
            '--active': i === active ? 1 : 0,
            '--offset': (active - i) / 3,
            '--abs-offset': Math.abs(active - i) / 4,
            'pointer-events': active === i ? 'auto' : 'none',
            'opacity': Math.abs(active - i) >= blurredStreamsCount ? '0' : '1',
            'display': Math.abs(active - i) > blurredStreamsCount ? 'none' : 'block',
          }}>
            {child}
          </div>
        ))}
        {active < count - 1 &&
          <button style={{
            color: "white",
            fontSize: "5rem",
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            top: "50%",
            zIndex: "2",
            cursor: "pointer",
            userSelect: "none",
            background: "unset",
            border: "unset",
            right: "0",
            transform: "translateX(100%) translatey(-50%)",
          }} onClick={() => setActive(i => i + 1)}>
            <ChevronRightIcon fontSize='large' />
          </button>}
      </div>
    );
  };

  // MUI data grid columns
  const columns = [
    {
      field: 'streamName',
      headerName: 'Stream Name',
      width: 160,
      renderCell: (params) => {
        return (
          <span onClick={() => handleEditStream(params.row.stream)}>{params.row.streamName}</span>
          // <TextField>Oye</TextField>
        )
      }
    },
    {
      field: 'date',
      headerName: 'Date',
      width: 160,
      renderCell: (params) => {
        return (
          <span onClick={() => handleEditStream(params.row.stream)}>{params.row.date}</span>
          // <TextField>Oye</TextField>
        )
      }
    },
    {
      field: 'product',
      headerName: 'Product',
      type: 'number',
      width: 80,
      renderCell: (params) => {
        return (
          <span onClick={() => handleEditStream(params.row.stream)}>{params.row.product}</span>
          // <TextField>Oye</TextField>
        )
      }
    },
    {
      field: 'category',
      headerName: 'Category',
      width: 120,
      renderCell: (params) => {
        return (
          <Button onClick={() => handleEditStream(params.row.stream)} color="warning" size="small" variant="outlined" sx={{ borderRadius: "32px", fontSize: "8px", color: "black" }}>100% recycled</Button>
        );
      }
    }
  ];

  return (
    <>
      <Container sx={{ overflow: "hidden" }}>
        {/* Modal Start */}
        {displayEditStream && (
          <Box
            sx={{
              width: "100vw",
              height: "100vh",
              top: "0px",
              left: "0px",
              position: "fixed",
              backgroundColor: "rgba(50, 50, 50, .5)",
              zIndex: "10",
            }}
          >
            <Box
              sx={{
                width: { xs: "80vw", lg: "50vw" },
                margin: "20vh auto",
                borderRadius: "5px",
                backgroundColor: "white",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
                gap: "20px",
                padding: "20px",
                fontSize: "1.3rem",
                border: "1px solid black",
                zIndex: "100",
                backgroundColor: "#EBFFF6"
              }}
            >
              <Box>
                <TextField label="Title: Name Your Stream" variant="standard" placeholder="" />
              </Box>
              <Box sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                gap: "4px",
                width: "700px"
              }}>
                <Box sx={{ display: "flex", flexDirection: 'column', gap: "8px" }}>
                  <TextField sx={{ width: '25ch' }}
                    label="Stream Description"
                    multiline
                    rows={11}
                    defaultValue=""
                  />
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker label="Stream Time" defaultValue={dayjs('2023-11-11T24:00')} />
                  </LocalizationProvider>
                </Box>
                <Box sx={{ display: "flex", flexDirection: 'column' }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateCalendar onChange={(date) => setDate(date)} />
                  </LocalizationProvider>
                  <Stack sx={{ display: "flex", justifyContent: "flex-end" }} spacing={2} direction="row">
                    <Button onClick={() => setDisplayEditStream(false)}>
                      Cancel
                    </Button>
                    <Button
                      color="warning"
                      onClick={() => handlePlanNewStream()}
                    >
                      Next
                    </Button>
                  </Stack>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
        {/* Modal End */}
        {/* Previous Streams (unique to streamer) */}
        <Box sx={{ 
            display: "flex", 
            flexDirection: "column", 
            alignItems: "center", 
            justifyContent: "space-between", 
            textAlign: "center", 
            margin: "5% 0 0 0",  
            marginBottom: "32px", 
            bgcolor: "#fff", 
            borderRadius: "32px", 
            padding: "1rem 0",
            background: componentBgdColor,
          }}>
          <Typography sx={{ color: "#37A520", fontWeight: "600", letterSpacing: "1px", fontSize: "24px"}}>Previous Streams</Typography>
          <Box sx={{
            width: "90vw",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "1rem",
          }}>
            <Carousel>
              {/* for production start */}
              {streams && streams.map((stream) => (
                <div key={stream.id} style={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "hsl(280deg, 40%, calc(100% - var(--abs-offset) * 50%))",
                  borderRadius: "1rem",
                  color: "#9ca3af",
                  textAlign: "justify",
                  transition: "all 0.3s ease-out",
                  position: "relative",
                }}>
                  <img src={`/assets/images/Carousel-1.png`} alt="" />
                  <PlayCircleIcon onClick={() => handleStreamClick(stream)} sx={{
                    position: "absolute", top: "50%", left: "45%", transform: "translate(50%, -50%)", cursor: "pointer"
                  }} fontSize='large' />
                  <VolumeUpIcon onClick={()=>toggleMute} sx={{ position: "absolute", bottom: "4px", left: "4px", cursor: "pointer" }} fontSize='medium' />
                  <CropFreeIcon sx={{ position: "absolute", bottom: "4px", right: "4px", cursor: "pointer" }} fontSize='medium' />
                </div>
              ))}
              {/* for production end */}

              {/* for development start */}
              {/* {[...new Array(10)].map((_, i) => (
                <div key={i} style={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "hsl(280deg, 40%, calc(100% - var(--abs-offset) * 50%))",
                  borderRadius: "1rem",
                  color: "#9ca3af",
                  textAlign: "justify",
                  transition: "all 0.3s ease-out",
                  position: "relative",
                }}>
                  <img src={`/assets/images/Carousel-1.png`} alt="" />
                  <PlayCircleIcon onClick={() => console.log("I was clicked")} sx={{
                    position: "absolute", top: "50%", left: "45%", transform: "translate(50%, -50%)", cursor: "pointer"
                  }} fontSize='large' />
                  <VolumeUpIcon onClick={()=>toggleMute} sx={{ position: "absolute", bottom: "4px", left: "4px", cursor: "pointer" }} fontSize='medium' />
                  <CropFreeIcon sx={{ position: "absolute", bottom: "4px", right: "4px", cursor: "pointer" }} fontSize='medium' />
                </div>
              ))} */}
              {/* for development end */}

            </Carousel>
          </Box>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: "8px", marginBottom: "32px"}}>
          {/* Stream Schedule */}
          <Box sx={{ display: "flex", flex: 1, flexDirection: "column", backgroundColor: "#fff", height: "384px", width: "384px", borderRadius: "32px", padding: "1rem", background: componentBgdColor, }}>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", padding: "2px 0" }}>
              <Typography sx={{ color: "#37A520", fontWeight: "600", letterSpacing: "1px", fontSize: "24px" }}>
                My Stream Schedule
              </Typography>
            </Box>
            <Box sx={{ height: 273, width: '100%' }}>
              <DataGrid
                // onRowClick={()=>{console.log("click one me"); handleClickStream(streams)}} {...streams}
                rows={streams.map((stream) => (
                  {
                    id: stream.id,
                    streamName: stream.title,
                    date: dayjs(stream.scheduled).format("MMM D, h:mm A"),
                    product: stream.products.length,
                    category: "",
                    stream: {
                      id: stream.id,
                      scheduled: stream.scheduled,
                      products: stream.products,
                      title: stream.title,
                      description: stream.description,
                    }
                  }
                ))}
                columns={columns}
                // hideFooter="true"
                pageSizeOptions={[3]}
                disableRowSelectionOnClick
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 3,
                    },
                  },
                }}
                options={{ selectableRows: false }}
              />
            </Box>

            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <VideocamIcon fontSize="large" />
              <Box>
                <Button
                  onClick={() => setDisplayEditStream(true)}
                  sx={{
                    bgcolor: "#37A520",
                    color: "#fff",
                    minWidth: "150px",
                    paddingX: 1,
                    borderRadius: 50,
                    '&:hover': { bgcolor: "#2e871b" }
                  }}>
                  New Livestream
                </Button>
              </Box>
            </Box>
          </Box>
          {/* My Metrics */}
          <Box sx={{ display: "flex", flex: 1, flexDirection: "column", backgroundColor: "#fff", height: "384px", width: "384px", borderRadius: "32px", padding: "1rem", background: componentBgdColor, }}>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", position: "relative" }}>
              <Typography sx={{ color: "#37A520", fontWeight: "600", letterSpacing: "1px", fontSize: "24px", alignSelf: "center" }}>
                My Metrics
              </Typography>
              <Switch sx={{ position: "absolute", right: 0 }} checked={toggled} onChange={handleChange} />
            </Box>
            {toggled ? (
              <ResponsiveContainer key={seed} width="99%" height="100%">
                <LineChart
                  width={500}
                  height={300}
                  data={chartData}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey="viewers" stroke="#167798" />
                  <Line type="monotone" dataKey="watched" stroke="#37A520" />
                  <Line type="monotone" dataKey="sold" stroke="#EA85C7" />
                </LineChart>
              </ResponsiveContainer>
            ) : (
              <ResponsiveContainer key={seed} width="99%" height="100%">
                <BarChart
                  width={100}
                  height={300}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                  data={barData}
                >
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip contentStyle={{ background: "#2a3447", borderRadius: "5px" }}
                    labelStyle={{ display: "none" }}
                    cursor={{ fill: "none" }} />
                  <Legend />
                  <Bar dataKey="viewers" fill="#167798" activeBar={<Rectangle fill="#2D9CDB" stroke="lightgray" />} />
                  <Bar dataKey="watched" fill="#37A520" activeBar={<Rectangle fill="#37A520" stroke="lightgray" />} />
                  <Bar dataKey="sold" fill="#EA85C7" activeBar={<Rectangle fill="#FF5B5B" stroke="lightgray" />} />
                </BarChart>
              </ResponsiveContainer>
            )}
            <Box sx={{ textAlign: "center" }}>
              <Button
                onClick={() => handleRefresh()}
                sx={{
                  bgcolor: "#37A520",
                  color: "#fff",
                  minWidth: "150px",
                  paddingX: 1,
                  borderRadius: 50,
                  '&:hover': { bgcolor: "#2e871b" }
                }}>
                Refresh
              </Button>

            </Box>
          </Box>
        </Box>
      </Container>
    </>
  );
}

export default StreamerHomePage;
