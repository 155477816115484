import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import Picker from "emoji-picker-react";
import {
  Box,
  Button,
  Typography,
  useTheme,
  alpha,
  Input,
  IconButton,
} from "@mui/material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ShareIcon from "@mui/icons-material/Share";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ClearIcon from "@mui/icons-material/Clear";
import ChatDemo from "./ChatDemo";

import { socket } from "../../socket";
import {
  Cancel,
  EmojiSymbolsOutlined,
  People,
  PeopleOutline,
  Send,
} from "@mui/icons-material";

function Chat({ viewerCount, streamId }) {
  //html ref for scrolling to bottom of comments
  const scrollRef = useRef(null);

  //get current user
  const user = useSelector((store) => store.user);
  console.log(user)
  //get all chats from db/store
  const allChats = useSelector((store) => store.chat);
  const store1 = useSelector((store) => store);
  console.log(store1)
  console.log(allChats)
  //get dayjs
  const dayjs = require("dayjs");
  //get mui theme
  const theme = useTheme();

  //get current stream id
  // const streamId = useSelector((store) => store.currentStream.id)

  const dispatch = useDispatch();

  //set chat open or closed
  const [chatOpen, setChatOpen] = useState(true);
  //bool to track if user is scrolling through chats
  const [scrolling, setScrolling] = useState(false);
  const [message, setMessage] = useState("");
  const [showPicker, setShowPicker] = useState(false);
  const onEmojiClick = (event, emojiObject) => {
    console.log(emojiObject, "blah blah");
    setMessage((prevInput) => prevInput + emojiObject.emoji);
    setShowPicker(false);
  };

  useEffect(() => {
    console.log(user, "hahah");
  });

  const sendMessage = () => {
    // socket.emit("send_message", { message, user})
    if (message && message.length > 1) {
      dispatch({
        type: "POST_CHAT",
        payload: {
          stream_id: streamId,
          text: message,
          user: user,
        },
        callback: () => {
          setMessage("");
          scrollToBottom();
        },
      });
      setMessage("");
    }
  };

  const scrollToBottom = () => {
    scrollRef.scrollIntoView();
    setScrolling(false);
  };

  // useEffect(() => {
  //   scrollToBottom();
  // }, [chatOpen]);

  // useEffect(() => {
  //   if (!scrolling) {
  //     scrollToBottom();
  //   }
  // }, [allChats]);

  useEffect(() => {
    console.log(streamId)
    console.log("oye")
    dispatch({
      type: "GET_CHAT",
      payload: streamId,
    });

    const receiveMessage = () => {
      dispatch({
        type: "GET_CHAT",
        payload: streamId,
      });
    };

    socket.on("add_text", receiveMessage);
    return () => {
      socket.off("add_text", receiveMessage);
    };
  }, []);

  const deleteComment = (id) => {
    dispatch({
      type: "DELETE_CHAT",
      payload: id,
    });
  };

  const handleScroll = (e) => {
    const atBottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (atBottom) {
      setScrolling(false);
    } else {
      setScrolling(true);
    }
  };

  const copyUrl = () => {
    navigator.clipboard.writeText(window.location.href);
  };

  const startChatDemo = () => {
    dispatch({
      type: "START_CHAT_DEMO",
    });
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        backgroundColor: "#ffffff",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        fontFamily: "sans-serif",
        justifyContent: "space-between",
        position: "relative",
      }}
    >
    {/* Header */}
      <Box
        id="chat header"
        sx={{
          borderTop: "1px solid rgba(50, 50, 50, .2)",
          borderBottom: "1px solid rgba(50, 50, 50, .2)",
          display: "flex",
          paddingY: "16px",
          paddingX: "16px",
          justifyContent: "space-between",
          backgroundColor: "white",
          alignItems: "center",
        }}
      >
        <div
          style={
            {
              display: "flex",
              width: "100%",
              justifyContent: "center"
            }
          }
        >
          <Typography sx={{ textAlign:"center", fontWeight: "800", fontFamily: "sans-serif" }}>
            Live Chat
          </Typography>
        </div>
        <Box
          sx={{
            paddingX: "16px",
            paddingY: "10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "10px",
            backgroundColor: "#CBE5DF",
            border: "1px solid #46E19B",
            color: "#167798",
          }}
        >
          <PeopleOutline
            sx={{
              paddingX: "5px",
              backgroundColor: "white",
            }}
          />
          {viewerCount}
        </Box>
      </Box>
      {/* Chat messages */}
      <Box
        id="chat messages"
        ref={scrollRef}
        className={"custom-scrollbar"}
        sx={{
          display: "flex",
          flexDirection: "column",
          // overflow: "scroll",
          overflowY: "scroll",
          paddingX: "16px",
          height: "100%",
        }}
      >
        {allChats?.map((chat) => {
          return (
            <Box
              id="chat wrapper"
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                paddingY: "5px",
              }}
            >
              <Box
                sx={{
                  width: "60%",
                  display: "flex",
                  gap: "3px",
                  alignItems: "center",
                }}
              >
                {user.is_streamer == true ? 
                  <Typography
                    sx={{ fontWeight: "600", fontFamily: "sans-serif", color: "#37A520" }}
                  >
                    {chat.username}
                  </Typography> :
                  <Typography
                    sx={{ fontWeight: "600", fontFamily: "sans-serif", color: "#167798" }}
                  >
                    {chat.username}
                  </Typography>
                }
                <Typography>:</Typography>
                <Typography sx={{ fontFamily: "sans-serif" }}>
                  {chat.text}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 3,
                  width: "40%",
                  justifyContent: "flex-end",
                  color: "rgba(50, 50, 50, .5)",
                }}
              >
                {dayjs(chat.timestamp).format("h:mmA MMM DD")}
                {user.is_streamer && (
                  <div onClick={()=>deleteComment(chat.id)}>
                    <Cancel sx={{ "&:hover": { color: "red" }, cursor: "pointer", fill: "", color: "rgba(50, 50, 50, )", }} />
                  </div>
                )}
              </Box>
            </Box>
          );
        })}
      </Box>
      {/* New chat box */}
      <Box
        id="enter new chat"
        sx={{
          backgroundColor: "white",
          display: "flex",
          flexDirection: "column",
          // alignItems: "center",
          // justifyContent: "space-between",
          paddingY: "16px",
          paddingX: "16px",
          gap: "5px",
        }}
      >
        {/* username box */}
        {/* <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: 12,
          }}
        >
          {/* <div
            style={{
              height: 44,
              width: 44,
              borderRadius: 25,
              backgroundColor: "lightsalmon",
            }}
          /> */}
          {/* {user.is_streamer == true ? 
            <span style={{ fontWeight: 600, color: "#37A520"}}>
            {user.username ? `@${user.username}` : "user"}
            </span> :
              <span style={{ fontWeight: 600, color: "#167798"}}>
              {user.username ? `@${user.username}` : "user"}
            </span>
          }
        </div> */} 
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          onKeyUp={(e) => {
            console.log("frig the whole system")
            if (e.key == "Enter") {
              console.log("fetched")
              sendMessage();
            }
          }}
        >
          <input
            type="text"
            placeholder="Type to add your message"
            value={message}
            style={{
              paddingLeft: "16px",
              paddingRight: "16px",
              paddingTop: "8px",
              paddingBottom: "8px",
              width: "100%",
            }}
            onChange={(event) => {
              setMessage(event.target.value);
            }}
          />
          {/* <img
            className="emoji-icon"
            src="https://icons.getbootstrap.com/assets/icons/emoji-smile.svg"
            onClick={() => setShowPicker((val) => !val)}
            style={{ padding: 10 }}
          /> */}
          {/* <div style={{ position: "absolute", cursor: "pointer", bottom: 0 }}>
            {showPicker && <Picker onEmojiClick={onEmojiClick} />}
          </div> */}
          <Box
            sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          >
            <div onClick={sendMessage}>
              <Send />
            </div>
          </Box>
        </div>
      </Box>
    </Box>
  );
}

export default Chat;
