import { REHYDRATE } from 'redux-persist/lib/constants'; 

import { combineReducers } from 'redux';

const streams = (state = [
  {
    "id": "dae90cae-9361-11ee-a34e-0242c0a8e002",
    "title": "Placeholder Stream",
    "description": "Describing the first stream!",
    "scheduled": "2023-11-17T18:12:00.000Z",
    "streamer_username": "Luna Opk 4",
    "products": [
        {
            "id": "d055acba-87ff-11ee-9528-0242ac120002",
            "name": "EarthHero Hand Crochet Frog Dog Toy",
            "image_url": "https://cdn.shopify.com/s/files/1/0617/2878/4620/products/Ware-of-the-Dog-Hand-Crochet-Frog-Dog-Toy-1-1_5f6d4cf7-0261-4147-bc75-e0b5db53e626_800x.jpg?v=1671244545",
            "description": "Give your pup a fun and quirky plush friend with the Hand Crochet Frog Dog Toy from Ware of the Dog! This eco-friendly dog toy is handmade by craftswomen in Turkey and made with 100% organic cotton and safe, natural dyes. Adorable and totally shakeable, it's perfect for playtime!",
            "platform_coupon_code": "58249",
            "platform_coupon_expiration": "2023-05-08T15:00:00+00:00",
            "url": "https://earthhero.com/collections/ware-of-the-dog/products/ware-of-the-dog-hand-crochet-frog-dog-toy#",
            "order": 4
        },
        {
            "id": "d055ab98-87ff-11ee-9528-0242ac120112",
            "name": "Bippy Bamboo Toilet Paper",
            "image_url": "https://cdn.shopify.com/s/files/1/0182/7697/4692/products/BIPPY_11222020_CAPTURE_1002_1200x.jpg?v=1620192269",
            "description": "It's like wiping with a cloud. Super smooth and always a lUUID-free clean. Your butt will love it. Bippy has two sides: a grippy side that deep cleans and a smooth side for dabbing dry. Whether you're a folder or a scruncher feel good knowing no trees were harmed to make Bippy.",
            "platform_coupon_code": "589293",
            "platform_coupon_expiration": "2023-05-20T15:00:00+00:00",
            "url": "https://heybippy.com/products/tree-free-tp-premium-3-ply?currency=USD&variant=31220214661214&utm_medium=cpc&utm_source=google&utm_campaign=Google%20Shopping&utm_source=google&utm_medium=cpc&utm_campaign=17391522901&utm_adgroup=&utm_keyword=&utm_matchtype=&utm_creative=&utm_device=c&utm_network=x&gclid=CjwKCAjw6IiiBhAOEiwALNqnceVlJ1Zup_WL6PXs3PT3pcIb-UZotKhacsm_JuLdtodSddgrlKLTvRoCSwsQAvD_BwE",
            "order": 3
        },
        {
            "id": "d054914a-87ff-11ee-9528-0242ac120002",
            "name": "Wild Routed Zion National Park Graphic Tee",
            "image_url": "https://cdn.shopify.com/s/files/1/0340/6843/0979/products/WR_Zion_Algae_Citron_Presale.png?v=1676581535&width=1280",
            "description": "A hand-drawn illustration featuring Angel's Landing at Zion National Park in Utah. This graphic is prUUIDed on a Citron color / Bella Canvas tee using carbon-negative algae ink® by Living Ink. PrUUIDed in USA. 100% Cotton Crew Neck by Bella Canvas.",
            "platform_coupon_code": "54321",
            "platform_coupon_expiration": "2023-04-29T15:00:00+00:00",
            "url": "https://wildrouted.com/collections/graphic-tees/products/zion-national-park-graphic-tee",
            "order": 1
        },
        {
            "id": "d05468f0-87ff-11ee-9528-0242ac120011",
            "name": "Nolé Radiance for Sensitive Skins Set",
            "image_url": "https://cdn.shopify.com/s/files/1/0605/8488/6478/products/CAJAS_NOLE_NUEVAS_0710_1728x.jpg?v=1645803244",
            "description": "Simply purifies, nourishes and revitalizes the scalp and hair while being extra gentle on the skin.",
            "platform_coupon_code": "1234",
            "platform_coupon_expiration": "2023-04-24T15:00:00+00:00",
            "url": "https://nolecare.com/collections/all/products/bundle-shampoo-conditioner-horsetail",
            "order": 2
        }
    ]
},
], action) => {
  switch (action.type) {
    case "SET_STREAMS":
      return action.payload;
    case REHYDRATE:
        return [...state];
    default:
      return state;
  }
};

const activeStreams = (state = 0, action) => {
  switch (action.type) {
    case "SET_ACTIVE_STREAMS":
      return action.payload;
    case REHYDRATE:
        return { ...state, persistedState: action.payload };
    default:
      return state;
  }
}

export default combineReducers({streams, activeStreams});
