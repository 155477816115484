import { Box, Button, Card, CardActionArea, CardContent, CardMedia, Stack, Typography, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import TooltipMUI from '@mui/material/Tooltip';

function StreamerControlsProduct({ product }) {
  const theme = useTheme();
  const currentProduct = useSelector((store) => store.currentProduct);

  return (
    <Card 
      key={product.id} 
      sx={{ maxWidth: 340, borderRadius: "5px", margin:"10px"}}>
      <CardActionArea>
        <CardMedia
          component="img"
          height="120"
          image={product.image_url}
          alt="product"
        />
      </CardActionArea>
      <CardContent>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Typography noWrap sx={{ display: { xs: "none", lg: "block" } }} gutterBottom variant="h6" component="div">
            {product.name}
          </Typography>
        </Box>
        <Typography
          align="left"
          variant="body2"
          color="text.secondary"
          sx={{
            display: { xs: "none", lg: "-webkit-box" },
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            WebkitLineClamp: 1,
            WebkitBoxOrient: 'vertical'
          }}>
          <TooltipMUI title={product.description}>
            {product.description}
          </TooltipMUI>
        </Typography>
        <Stack align="left" direction="row" spacing={2} sx={{
            margin:"10px 0px 0px 0px"
          // centers the category lables
          // display: { xs: "none", md: "inline-flex" }
        }}>
          <Button size="small" variant="outlined" sx={{ borderRadius: "32px", fontSize: "8px", color: "black" }}>{product.category != null ? product.category : "100% recycled"}</Button>
          {/* <Button size="small" variant="outlined" sx={{ borderRadius: "32px", fontSize: "8px", color: "black" }}>Biodegradable</Button> */}
        </Stack>
      </CardContent>
    </Card>
  );
}

export default StreamerControlsProduct;
