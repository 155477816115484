import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { TextField, Button, Box, FormControl, FormControlLabel, Checkbox, ImageList } from "@mui/material";

function LoginForm() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const errors = useSelector((store) => store.errors);
  const dispatch = useDispatch();
  const history = useHistory();

  const login = (event) => {
    event.preventDefault();

    if (username && password) {
      dispatch({
        type: "LOGIN",
        payload: {
          username: username,
          password: password,
        },
      });
      history.push("/home");
    } else {
      dispatch({ type: "LOGIN_INPUT_ERROR" });
    }
  }; // end login

  return (
    <>
      <form onSubmit={login}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh"
          }}
        >
          <Box sx={{
            backgroundColor: "#fff",
            display: "flex",
            padding: "4rem 2rem",
            borderRadius: "0.5rem",
            gap: "0 2rem",
            position: "relative",
            overflow: "hidden"
          }}>
            <img
              style={{ position: "absolute", top: "0", left: "0" }}
              src="/assets/images/leafLeft.png" alt="" />
            <img
              style={{ position: "absolute", bottom: "0", right: "0" }}
              src="/assets/images/leafRight.png" alt="" />
            <Box sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-around",
              gap: "0.625rem",
              padding: "1rem 1rem",
              height: "100%"
            }}>
              {/* error box causing an error rn */}
              {/* {errors.loginMessage && (
                <Box
                  sx={{ width: "50%", textAlign: "center", margin: "0.125rem auto" }}
                  className="alert"
                  role="alert"
                >
                  {errors.loginMessage}
                </Box>
              )} */}
              <Box sx={{
                objectFit: "cover"
              }}>
                <img src="/assets/images/logo.png" alt="" />
              </Box>
              <Box sx={{
                objectFit: "cover"
              }}>
                <img src="/assets/images/coffee.png" alt="" />
              </Box>
            </Box>
            <Box sx={{
              display: "flex",
              flexDirection: "column",
              boxShadow: "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)",
              width: "384px",
              padding: "1rem 1rem",
              zIndex: "1",
              backgroundColor: "#fff",
              width: "380px",
              borderRadius: "1rem"
            }}>
              <Box
                sx={{
                  textAlign: "center"
                }}>
                <h1 style={{ fontWeight: "bold", color: "#32324D" }}>Welcome back!</h1>
                <p style={{ color: "#666687" }}>Log in to your Omi live account</p>
              </Box>
              <TextField
                label="Username"
                sx={{
                  marginBottom: "8px",
                  '& .MuiInputBase-root': {
                    backgroundColor: '#F7F7F7',
                    color: '#A2A0A9'
                  },
                }}
                type="text"
                placeholder="username"
                required
                value={username}
                onChange={(event) => setUsername(event.target.value)}
              />
              <TextField
                label="Password"
                sx={{
                  '& .MuiInputBase-root': {
                    backgroundColor: '#F7F7F7',
                    color: '#A2A0A9'
                  },
                }}
                type="password"
                placeholder="password"
                required
                value={password}
                onChange={(event) => setPassword(event.target.value)}
              />
              <FormControlLabel
                sx={{
                  color: "#32324D",
                  '& MuiTypography-root': {
                    fontSize: "10px",
                  },
                }}
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              />
              <Button sx={{
                fontFamily: "Poppins",
                marginBottom: "0.625rem"
              }} type="submit">Login</Button>
              <Box sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: "2px"
              }}>
                <button
                  style={{
                    color: "#8083A3", borderBottom: "1px solid #8083A3", fontSize: "14px"
                  }}
                  type="button"
                  className="btn btn_asLink"
                  onClick={() => {
                    history.push('/registration');
                  }}
                >
                  Forgot Password
                </button>
                <span style={{ color: "#8083A3", fontSize: "14px" }}>Or</span>
                <ImageList sx={{
                  display: "flex",
                  margin: "0"
                }}>
                  <img style={{ cursor: "pointer" }} src="/assets/images/google.png" alt="" loading="lazy" />
                  <img style={{ cursor: "pointer" }} src="/assets/images/fb.png" alt="" loading="lazy" />
                  <img style={{ cursor: "pointer" }} src="/assets/images/twitter.png" alt="" loading="lazy" />
                </ImageList>
              </Box>
            </Box>
          </Box>
        </Box>
      </form>
    </>
  );

}

export default LoginForm;
