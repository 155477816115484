import { REHYDRATE } from 'redux-persist/lib/constants'; 
import { combineReducers } from 'redux';

// loginMessage holds the string that will display
// on the login screen if there's an error
const loginMessage = (state = '', action) => {
  switch (action.type) {
    case 'CLEAR_LOGIN_ERROR':
      return '';
    case 'LOGIN_INPUT_ERROR':
      return 'Enter your username and password!';
    case 'LOGIN_FAILED':
      return "Oops! The username and password didn't match. Try again!";
    case 'LOGIN_FAILED_NO_CODE':
      return 'Oops! Something went wrong! Is the server running?';
    case REHYDRATE:
      return { ...state, persistedState: action.payload };
    default:
      return state;
  }
};

// registrationMessage holds the string that will display
// on the registration screen if there's an error
const registrationMessage = (state = '', action) => {
  switch (action.type) {
    case 'CLEAR_REGISTRATION_ERROR':
      return '';
    case 'REGISTRATION_INPUT_ERROR':
      return 'Input error, please try again';
    case 'REGISTRATION_FAILED':
      if (action.payload == "Error: Request failed with status code 409") {
        return "Username or email is already assigned to an account. Try again!"
      }
      else if (action.payload == "Error: Request failed with status code 400") {
        return "Password must be at least 8 characters long. It must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
      }
      else 
        return `Can't register: ${action.payload}`;
    case REHYDRATE:
        return { ...state, persistedState: action.payload };
    default:
      return state;
  }
};

const verificationMessage = (state = '', action) => {
    switch (action.type) {
        case "VERIFICATION_INPUT_ERROR":
          return 'Please enter the 6-digit code sent to your email.'
          case 'VERIFICATION_FAILED':
            return "Oops! That code didn't work. Try again!";
        case 'RESENT_VERIFICATION_FAILED':
            return "Failed to resend verification code."
        case 'RESENT_VERIFICATION_NO_EMAIL':
            return "Error! No email address to resend code. Please go back to sign up."
        case 'RESEND_VERIFICATION_CODE_SUCCESS':
            return "Verification code resent to your email successfully."
        case REHYDRATE:
            return { ...state, persistedState: action.payload };
        default:
          return state;
      }
}

// make one object that has keys loginMessage, registrationMessage
// these will be on the redux state at:
// state.errors.loginMessage and state.errors.registrationMessage
export default combineReducers({
  loginMessage,
  registrationMessage,
  verificationMessage,
});
