import React from 'react'
import { Container, Typography, Box } from '@mui/material'
import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

function PaymentPortal() {
    // functionality for MUI History table
    function createHistoryData(historyDate, historyDesc, historyAmount, historyInvoice) {
        return { historyDate, historyDesc, historyAmount, historyInvoice };
    }
    // data for history table
    const historyRows = [
        createHistoryData('Nov 1st @7:00pm EST', 'Lorem ipsum dolor sit amet consectetur adipisicing elit', '$55.72', <FolderOpenOutlinedIcon />),
        createHistoryData('Nov 1st @5:00pm EST', 'Lorem ipsum dolor sit amet consectetur adipisicing elit', '$15.72', <FolderOpenOutlinedIcon />),
        createHistoryData('Sept 24th @1:00pm EST', 'Lorem ipsum dolor sit amet consectetur adipisicing elit', '$55.72', <FolderOpenOutlinedIcon />),
        createHistoryData('Feb 24th @3:00pm EST', 'Lorem ipsum dolor sit amet consectetur adipisicing elit', '$45.72', <FolderOpenOutlinedIcon />),
        createHistoryData('Jan 24th @4:00pm EST', 'Lorem ipsum dolor sit amet consectetur adipisicing elit', '$35.72', <FolderOpenOutlinedIcon />),
    ];
    // functionality for MUI Request table
    function createRequestData(requestDate, requestStatus, requestID) {
        return { requestDate, requestStatus, requestID };
    }
    // data for request table
    const requestRows = [
        createRequestData('10/1/2023', 'COMPLETE', '550e8400-e29b-41d4-a716'),
        createRequestData('11/15/2023', 'FOLLOW UP', '550e8400-e29b-41d4-a716'),
        createRequestData('11/1/2023', 'PENDING', '550e8400-e29b-41d4-a716'),
        createRequestData('11/2/2023', 'PENDING', '550e8400-e29b-41d4-a716'),
        createRequestData('11/3/2023', 'PENDING', '550e8400-e29b-41d4-a716'),
    ];

    return (
        <Container>
            <Box sx={{
                padding: "1rem"
            }}>
                <Typography variant='h5' align="center" sx={{ fontWeight: "600" }}>Payment Portal</Typography>
            </Box>
            <Box sx={{
                backgroundColor: "#fff",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                flexDirection: "column",
                padding: "1rem",
                borderRadius: "32px",
                gap: "1rem",
            }}>
                <Box sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    // gridAutoRows: "minmax(0, 1fr)",
                    gap: "1rem",
                    width: "100%",
                }}>
                    <Box sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        flexDirection: "column",
                        border: "1px solid #DCDCE4",
                        borderRadius: "32px",
                        padding: "1rem",
                    }}>
                        <Typography sx={{ fontWeight: "600" }} variant="h6" align="center" color="#BDC1D6">Commissions Cash</Typography>
                        <Typography sx={{ fontWeight: "600" }} variant="h3" align="center" color="#378520" >$33.52</Typography>
                        <Typography sx={{ fontWeight: "600" }} variant="h6" align="center">Sourced Sales: 10</Typography>
                    </Box>
                    <Box sx={{
                        display: { xs: "none", sm: "flex" },
                        flexDirection: "column",
                        flex: 1,
                        border: "1px solid #DCDCE4",
                        borderRadius: "32px",
                        padding: "1rem",
                        height: "256px"
                    }}>
                        <Typography variant='h5' align="center" sx={{ fontWeight: "600" }}>Requests</Typography>
                        <TableContainer>
                            <Table sx={{ maxWidth: 880, width: { xs: 380, md: 800, lg: 860 } }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ fontWeight: "600", color: "#BDC1D6" }}>Date</TableCell>
                                        <TableCell sx={{ fontWeight: "600", color: "#BDC1D6" }}>Status</TableCell>
                                        <TableCell sx={{ fontWeight: "600", color: "#BDC1D6" }}>Request ID</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {requestRows.map((row, i) => (
                                        <TableRow
                                            key={i}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">{row.requestDate}</TableCell>
                                            <TableCell>
                                                {row.requestStatus == "COMPLETE" && <span style={{ backgroundColor: "#37A520", padding: "0.125em", color: "#fff", borderRadius: "4px" }}>{row.requestStatus}</span>}
                                                {row.requestStatus == "FOLLOW UP" && <span style={{ backgroundColor: "#BF0000", padding: "0.125em", color: "#fff", borderRadius: "4px" }}>{row.requestStatus}</span>}
                                                {row.requestStatus == "PENDING" && <span style={{ backgroundColor: "#BDC1D6", padding: "0.125em", color: "#fff", borderRadius: "4px" }}>{row.requestStatus}</span>}
                                            </TableCell>
                                            <TableCell>{row.requestID}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Box>
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    border: "1px solid #DCDCE4",
                    borderRadius: "32px",
                    padding: "1rem",
                    height: "256px"
                }}>
                    <Typography gutterBottom variant='h5' sx={{ fontWeight: "600" }}>History</Typography>
                    <TableContainer>
                        <Table sx={{ maxWidth: 1080, width: { xs: 380, md: 800, lg: 1060 } }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ fontWeight: "600", color: "#BDC1D6" }}>Date</TableCell>
                                    <TableCell sx={{ fontWeight: "600", color: "#BDC1D6" }}>Description</TableCell>
                                    <TableCell sx={{ fontWeight: "600", color: "#BDC1D6" }}>Amount</TableCell>
                                    <TableCell sx={{ fontWeight: "600", color: "#BDC1D6" }}>Invoice</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {historyRows.map((row, i) => (
                                    <TableRow
                                        key={i}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">{row.historyDate}</TableCell>
                                        <TableCell>{row.historyDesc}</TableCell>
                                        <TableCell sx={{ fontWeight: "600" }}>{row.historyAmount}</TableCell>
                                        <TableCell>{row.historyInvoice}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Box>
        </Container>
    )
}

export default PaymentPortal