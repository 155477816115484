import React from 'react';
import LoginForm from '../LoginForm/LoginForm';

function LoginPage() {

  return (
    <>
      <LoginForm />
    </>
  );
}

export default LoginPage;
