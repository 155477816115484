import React, { useEffect, useState } from 'react'
import { Box, FormControl, InputLabel, OutlinedInput, InputAdornment, Card, CardActionArea, CardContent, CardMedia, Button, Typography, CardActions, Container, Stack } from '@mui/material'
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import TwitterIcon from '@mui/icons-material/Twitter';
import SearchIcon from '@mui/icons-material/Search';
import InstagramIcon from '@mui/icons-material/Instagram';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import TooltipMUI from '@mui/material/Tooltip';
import ProductListItem from '../ViewerComponents/ViewerProductsList/ProductListItem/ProductListItem';

// slick slider imports
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import VideoListItem from '../VideosPage/VideoListItem';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

function Searchpage() {
    const products = useSelector((store) => store.allProducts);
    const streams = useSelector((store) => store.streams.streams);
    const searchResults = useSelector((store) => store.searchResults)
    const user = useSelector((store) => store.user);
    // use this object for products, streams and streamers
    
    const history = useHistory();
    const dispatch = useDispatch();

    // const query = location.state?.queryParam
    const query = useParams().query != null ? useParams().query : "";


    // load search results
    useEffect(() => {
        dispatch({type: "SEARCH_EVERYTHING", payload: query != "" ? query : ""})
        console.log(searchResults)// dispatch({type: "SET_SEARCH_RESULTS", payload: query != "" ? query : ""})
        // products = searchResults.Products
        // streams = searchResults.Streams
    }, [])

    // react-slick syntax | docs: https://react-slick.neostack.com/
    let settings = {
        className: "center",
        // centerMode: true,
        dots: false,
        infinite: true,
        centerPadding: "-8px",
        speed: 500,
        slidesToShow: 3,
    }

    const [searchQuery, setSearchQuery] = useState("");
    const captureSearchQuery = (e) => {
        e.preventDefault();
        setSearchQuery(e.target.value)
        console.log(searchQuery)
    }

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {

            history.push({
                pathname: `/search/${searchQuery}`,
                // state: { queryParam: searchQuery,  }
            })
        }
    }

    return (
        <>
            <Container maxWidth="xl" 
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                gap: "32px 0",
            }}>
                {user.id && (
                        <FormControl sx={{
                            flexGrow: 1,
                            maxWidth: "800px",
                            display: { xs: "flex", md: "none" },
                            marginLeft: "2vh",
                            marginRight: "2vh",
                            '& .MuiInputBase-root': { borderRadius: "32px" }
                        }} variant="outlined">
                            <InputLabel htmlFor="Products">{window.location.href.indexOf("search") > -1 ? useParams().query : "Search Anything"}</InputLabel>
                            <OutlinedInput
                                onKeyDown={handleKeyDown}
                                type='text'
                                onChange={captureSearchQuery}
                                placeholder={window.location.href.indexOf("search") > -1 ? useParams().query : "Search Anything"}
                                // value={window.location.href.indexOf("search") > -1 ? useParams().query : ""}
                                endAdornment={
                                    <InputAdornment position="end">
                                        {/*  need to update to actually search */}
                                        <SearchIcon
                                            onClick={() => history.push({
                                                pathname: `/search/${searchQuery}`,
                                                // state: { queryParam: searchQuery,  }
                                            })}
                                            edge="end"
                                            color="primary"
                                            sx={{ cursor: "pointer" }}
                                        />
                                    </InputAdornment>
                                }
                                // label="Search Everything"
                            />
                        </FormControl>
                    )}
                {/* Products */}
                <Box>
                    <Typography sx={{ fontSize: "24px", fontWeight: "600", margin: "2% 0" }}>Products</Typography>
                    <Box>
                        {searchResults.data.Products.length > 0 ? 
                        <Slider {...settings}>
                            {searchResults.data.Products.map((product) => (
                                <ProductListItem product={product} />
                            ))}
                        </Slider> : "No product results." }
                    </Box>
                </Box>
                {/* Streams */}
                <Box>
                    <Typography sx={{ fontSize: "24px", fontWeight: "600", margin: "2% 0"  }}>Streams</Typography>
                    <Box>
                        {searchResults.data.Streams.length > 0 ? 
                        <Slider {...settings}>
                            {searchResults.data.Streams.map((stream) => (
                                <VideoListItem stream={stream}/>
                            ))}
                        </Slider> : "No stream results."
                        }
                    </Box>
                </Box>
                {/* Streamers  */}
                {/* <Box sx={{
                    marginBottom: "64px"
                }}>
                    <Typography sx={{ fontSize: "24px", fontWeight: "600", margin: "2% 0"  }}>Streamers</Typography>
                    <Box>
                        {searchResults.data.Streams.length > 0 ? 
                        <Slider {...settings}>
                            {[...new Array(4)].map((_, i) => (
                                <Card key={i} sx={{ maxWidth: 380, borderRadius: "5px" }}>
                                    <CardActionArea>
                                        <CardMedia
                                            component="img"
                                            height="140"
                                            image="/assets/images/streamer-1.png"
                                            alt="product"
                                        />
                                    </CardActionArea>
                                    <CardContent>
                                        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                            <Typography noWrap sx={{ display: { xs: "none", lg: "block" } }} gutterBottom variant="h6" component="div">
                                                {streams.streamer_username} Emily Green
                                            </Typography>
                                            <Box sx={{ cursor: "pointer", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                <TwitterIcon />
                                                <img style={{ height: "25px", width: "25px" }} src="/assets/images/tiktok.png" alt="" loading="lazy" />
                                                <InstagramIcon />
                                            </Box>
                                        </Box>
                                        <Typography
                                            variant="body2"
                                            color="text.secondary"
                                            sx={{
                                                display: { xs: "none", lg: "-webkit-box" },
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                WebkitLineClamp: 2,
                                                WebkitBoxOrient: 'vertical',
                                            }}>
                                            <TooltipMUI title="Environmentalist, nature lover, and zero-waste advocate. Emily is passionate about living a sustainable lifestyle and promoting eco-friendly products. She's an active member of several online eco-communities">
                                                Environmentalist, nature lover, and zero-waste advocate. Emily is passionate about living a sustainable lifestyle and promoting eco-friendly products. She's an active member of several online eco-communities.
                                            </TooltipMUI>
                                        </Typography>
                                        <Typography sx={{ display: { xs: "none", lg: "block" } }} gutterBottom variant="h6" component="div">
                                            Streams About
                                        </Typography>
                                        <Stack direction="row" spacing={2} sx={{
                                            display: { xs: "none", md: "inline-flex" }
                                        }}>
                                            <Button color="warning" size="small" variant="outlined" sx={{ borderRadius: "32px", fontSize: "8px", color: "black" }} >Zero-Waste Essentials</Button>
                                            <Button size="small" variant="outlined" sx={{ borderRadius: "32px", fontSize: "8px", color: "black" }}>Sustainable Fashion</Button>
                                            <Button color="warning" size="small" variant="outlined" sx={{ borderRadius: "32px", fontSize: "8px", color: "black" }} >Fair Trade Products </Button>
                                        </Stack>
                                    </CardContent>
                                </Card>
                            ))}
                        </Slider> : "No streamer results."
                        }
                    </Box> 
                </Box>  */}
            </Container>
        </>
    )
}

export default Searchpage