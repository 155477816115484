import React, { useEffect, useRef } from "react";
import { Box, Card, CardActionArea, CardContent, CardMedia, Button, Typography, FormGroup, FormControlLabel, Switch, Tooltip, IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import ViewerProductsList from "../ViewerProductsList/ViewerProductsList";
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import LiveVideo from "../LiveVideo/LiveVideo";
import dayjs from 'dayjs';
import TooltipMUI from '@mui/material/Tooltip';
import { socket } from '../../../socket'

// slick slider imports
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ProductListItem from "../ViewerProductsList/ProductListItem/ProductListItem";
import { GridDeleteIcon } from "@mui/x-data-grid";

function ViewerHomePage() {
  const [toggled, setToggled] = useState(true);
  const handleChange = () => {
    setToggled(!toggled);
  };
  const history = useHistory();
  // const products = useSelector((store) => store.allProducts);
  const streams = useSelector((store) => store.streams.streams);
  const currentStream = useSelector((store) => store.currentStream);
  const user = useSelector((store) => store.user);
  const activeStreams = useSelector((store) => store.streams.activeStreams);
  const playerRef = useRef(null);
  const [live, setLive] = useState(true);
  const [muted, setMuted] = useState(true);
  const dispatch = useDispatch();
  const [displayConfirmEndStream, setDisplayConfirmEndStream] = useState(false);
  const [viewerCount, setViewerCount] = useState(0);
  const currentProduct = useSelector((store) => store.currentProduct);
  console.log(streams.length, "init"); //6
  console.log(Object.keys(currentStream).length, "mid"); //6
  console.log(Object.keys(currentProduct).length, "end"); //9
  const streamID = useSelector((store) => store.streams.activeStreams);
  const [showProducts, setShowProducts] = useState(false);
  const [liked, setLiked] = useState(false);
  let arrayCount = streams.length
  const [seed, setSeed] = useState(true);
  const handleRefresh = () => {
    setSeed(!seed)
  }

  useEffect(() => {
    dispatch({
      type: "GET_WISHLIST",
    });
  }, [])
  const products = useSelector((store) => store.wishlist)
  console.log(products)

  useEffect(() => {
    if (playerRef?.current?.options_?.id) {
      document.getElementById(
        playerRef.current.options_.id + "_html5_api"
      ).muted = muted;
    }
  }, [muted, playerRef]);

  useEffect(() => {
    dispatch({ type: "FETCH_ACTIVE_STREAMS" });
    socket.emit("join stream", user.id);
    //create socket listener for stream closed emit, set live = false
    socket.on("stream_closed", (user) => {
      setDisplayConfirmEndStream(true);

      if (user === currentStream.streamer_username) {
        // setTimeout(() => setLive(false), 5000)
        setLive(false);
      }
    });
    socket.on("update viewer count", (count) => {
      setViewerCount(count);
    });
    return () => {
      socket.emit("leave stream");
      socket.off("stream_closed");
      socket.off("update viewer count");
    };
  }, []);

  // react-slick syntax | docs: https://react-slick.neostack.com/
  let settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
  }

  return (
    <>
      {/* Main */}
      <Box sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        gap: "4px 8px",
        width: "100%",
        height: "80vh"
      }}>
        <Box sx={{ display: "flex", height: "100%", width: "100%", justifyContent: "center", }}>
          {/* live indicator WIP - make it blink */}
          {/* <Box sx={{ position: "absolute", top: "10%", left: "1%", zIndex: 99 }}>
            <Button variant="contained"
              size="large"
              sx={{
              color: "#fff", bgcolor: "#BF0000",
              '&:hover': {
                bgcolor: '#730000',
              }
            }}>LIVE</Button>
          </Box> */}
          {/* streamContainer */}
          <Box>
            <Tooltip title="Click inside the video box to watch the livestream!">
            <Box
              onClick={() => history.push(`/live/${streams.streamer_username}/${activeStreams.id}`)} 
              id="stream and products"
              sx={{
                minWidth: "67vw",
                // height: "100%",
                backgroundColor: "#949494",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                position: "center",
                marginBottom: "5vh"
              }}
            > 
                {/* <IconButton> */}
                  {/* <GridDeleteIcon /> */}
                {/* </IconButton> */}
              {/* activeStreams.id == null ? */}
                <LiveVideo
                  username={streams.streamer_username}
                  playerRef={playerRef}
                  setLive={setLive}
                  streamID={activeStreams.id}
                  vodURL={activeStreams.vod_url}
                  // showPlayButton={true}
                />
            </Box>
            </Tooltip>
          </Box>
          {/* productsContainer */}
          {/* <Box sx={{
            display: { xs: "none", lg: "flex" },
            flexGrow: 1,
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
            maxWidth: "33%",
            padding: "16px 8px 0",
            gap: "8px",
            backgroundColor: "#EBFFF6"
          }}> */}
            {/* <Box sx={{
              backgroundColor: "#fff",
              display: { xs: "none", lg: "flex" },
              alignItems: "center",
              justifyContent: "center",
              padding: "1rem 0",
              width: "100%",
              position: "relative"
            }}>
              {/* toggle syntax 
              <FormGroup>
                <FormControlLabel
                  label={<Typography sx={{ fontSize: "24px", fontWeight: "600" }}>{seed ? "Hot Products" : "Recommended Products"} </Typography>}
                  labelPlacement="start"
                  control={<Switch sx={{ position: "absolute", right: 0 }} checked={seed} onChange={() => handleRefresh()} />}
                />
              </FormGroup>
            </Box> */}
            {/* productCards */}
            {/* <Box sx={{
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
              gap: "16px",
              padding: "1rem",
            }}> */}
              
              {/* for production start */}
               {/* products.slice(0, 4).map((product) => (
                //<ProductListItem product={product}/>
                // <Card
                //   key={product.id}
                //   sx={{ maxWidth: 280, borderRadius: "32px" }}>
                //   <CardActionArea onClick={() => history.push('/viewer-products')}>
                //     <CardMedia
                //       component="img"
                //       height="120"
                //       image={product.image_url}
                //       alt=""
                //     />
                //   </CardActionArea>
                //   <CardContent>
                //     <Typography noWrap sx={{ display: { xs: "none", lg: "block" } }} gutterBottom variant="h6">
                //       {product.name}
                //     </Typography>
                //     <Typography
                //       variant="body2"
                //       sx={{
                //         display: { xs: "none", lg: "-webkit-box" },
                //         overflow: 'hidden',
                //         WebkitBoxOrient: 'vertical',
                //         WebkitLineClamp: 2,
                //       }}
                //     >
                //       <TooltipMUI followCursor title={product.description}>
                //         {product.description}
                //       </TooltipMUI>
                //     </Typography>
                //   </CardContent>
                // </Card>
              ))}
              {/* for production end */}

              {/* for development start */}
              {/* {[...new Array(4)].map((_, i) => (
                <Card
                  key={i}
                  sx={{ maxWidth: 280, borderRadius: "32px" }}>
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      height="120"
                      image="/assets/images/product-3.png"
                      alt=""
                    />
                  </CardActionArea>
                  <CardContent>
                    <Typography noWrap sx={{ display: { xs: "none", lg: "block" } }} gutterBottom variant="h6">
                      BLUELAND Toilet Bowl Cleaner Set
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        display: { xs: "none", lg: "-webkit-box" },
                        overflow: 'hidden',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 2,
                      }}>
                      <TooltipMUI followCursor title="The first plastic-free toilet bowl cleaner tablet that's tough on stains but gentle on the planet. No more harsh chemicals, harmful ingredients or plastic packaging our tablets work hard with plant-based power.">
                        The first plastic-free toilet bowl cleaner tablet that's tough on stains but gentle on the planet. No more harsh chemicals, harmful ingredients or plastic packaging our tablets work hard with plant-based power.
                      </TooltipMUI>
                    </Typography>
                  </CardContent>
                </Card>
              ))} */}
              {/* for development end */}

            {/* </Box> */}
          {/* </Box> */}
        </Box>

        {/* Bottom Carousel Start*/}
        <Box sx={{ width:"100%", background:"#167798"  }}>
          <Box sx={{width:"100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-around",  }}>
            {arrayCount >= 1 && streams.map((stream) => (
              <Card key={stream.id} sx={{  position: "relative" }}>
                {stream.live == true  &&
                  <Box sx={{ position: "absolute", top: 8, left: 8, zIndex: 99 }}>
                    <Button variant="contained" sx={{
                      color: "#fff", bgcolor: "#BF0000",
                      '&:hover': {
                        bgcolor: '#730000',
                      }
                    }}>LIVE</Button>
                  </Box>
                }
                <Box sx={{ position: 'relative', borderRadius: 0, }}>
                  <CardActionArea onClick={() => history.push(`/live/${stream.streamer_username}/${stream.id}`)}>
                    <CardMedia
                      component="img"
                      height="250px"
                      width="187px"
                      image={stream.thumbnail != null ? stream.thumbnail : "/assets/images/omi_logo.png" }
                      // image="/assets/images/product-2.png"
                      alt="stream thumbnail"
                    />
                    <Box
                      sx={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        width: '100%',
                        backgroundColor: 'rgba(255, 255, 255, 0.52)',
                        color: 'white',
                        padding: '8px',
                        display: { xs: "none", lg: "block" },
                      }}
                    >
                      <Typography noWrap sx={{ display: { xs: "none", lg: "block", fontWeight: 600, } }} gutterBottom variant="body1">
                        {stream.title}
                      </Typography>
                      <Typography noWrap sx={{ display: { xs: "none", lg: "block" } }} variant="body2">
                        @{stream.streamer_username}
                      </Typography>
                      <Typography noWrap sx={{ display: { xs: "none", lg: "block", fontStyle: "italic" } }} variant="body2">
                        {dayjs(stream.scheduled).format("MMM D, YYYY")}
                      </Typography>
                    </Box>
                  </CardActionArea>
                </Box>
              </Card>
            ))}
          </Box>
          {/* Bottom Carousel End */}
        </Box>
      </Box>
    </>
  );
}

export default ViewerHomePage;