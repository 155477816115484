import React, { useState } from 'react'
import { Box, Button, FormControl, InputAdornment, InputLabel, OutlinedInput, AppBar, Toolbar, Link, Container, IconButton, Typography } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search';
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import { useDispatch } from "react-redux";
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

function Navbar({color="#ffffff", previousSearchQuery}) {
    const user = useSelector((store) => store.user);
    const history = useHistory();
    const dispatch = useDispatch();

    // account menu functionality
    const [dropdown, setDropdown] = useState(null);
    const [searchQuery, setSearchQuery] = useState("");
    const open = Boolean(dropdown);
    const handleClick = (event) => {
        setDropdown(event.currentTarget);
    };
    const handleClose = () => {
        setDropdown(null);
    };

    const captureSearchQuery = (e) => {
        e.preventDefault();
        setSearchQuery(e.target.value)
        console.log(searchQuery)
    }

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {

            history.push({
                pathname: `/search/${searchQuery}`,
                // state: { queryParam: searchQuery,  }
            })
        }
    }
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const pages = ['Home', 'Videos', 'Catalog', "Search", 'Favorites'];
    const links = ["/", "/videos-on-demand", "/viewer-products", "search", "/wishlist"]

    return (
        <AppBar position="static" sx={{
            padding: "8px 0",
            backgroundColor: color,
            boxShadow: "none",
            width: "100%",
            overflowX: "hidden"
            // marginBottom: "8px"
        }}>
            <Container maxWidth="2xl">
                <Toolbar disableGutters sx={{ display: "flex", width: "100%", justifyContent:"space-between"  }}>
                    {/* navLeft */}
                    <Box
                        onClick={() => history.push("/")}
                        // sx={{ cursor: "pointer", }}
                        sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' }, mr:1, justifyContent: "space-between" }}
                        component="img"
                        src="/assets/images/navlogo.png"
                        alt="logo"
                    />
                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleOpenNavMenu}
                        color="inherit"
                        >
                        <MenuIcon sx={{color: "#167798", fontSize: "5vh"}} />
                        </IconButton>
                        <Menu
                        id="menu-appbar"
                        anchorEl={anchorElNav}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        open={Boolean(anchorElNav)}
                        onClose={handleCloseNavMenu}
                        sx={{
                            display: { xs: 'block', md: 'none' },
                        }}
                        >
                        {pages.map((page, index) => (
                            <MenuItem key={page} onClick={handleCloseNavMenu}>
                            <Typography 
                                onClick={() => history.push(links[index])}
                                textAlign="center">{page}</Typography>
                            </MenuItem>
                        ))}
                        </Menu>
                    </Box>
                    {user.id && (
                        <>
                        <Link sx={{ 
                            fontSize: "2.5vh", fontWeight: "600", cursor: "pointer", 
                            marginLeft: "2vh",
                            marginRight: "2vh",
                            flexGrow: 0, display: { xs: 'none', sm: 'none', md:"flex", lg: "flex" } }} underline="none" onClick={() => history.push("/videos-on-demand")}>Videos</Link>
                        <Link sx={{ 
                            fontSize: "2.5vh", fontWeight: "600", cursor: "pointer", 
                            marginLeft: "2vh",
                            marginRight: "2vh",
                            display: { xs: 'none', sm: 'none', md:"flex", lg: "flex"  }}} underline="none" onClick={() => history.push("/viewer-products")}>Catalog</Link>
                        </>
                    )}

                    {/* navMiddle */}
                    {user.id && (
                        <FormControl sx={{
                            flexGrow: 1,
                            maxWidth: "800px",
                            display: { xs: "none", md: "flex" },
                            marginLeft: "2vh",
                            marginRight: "2vh",
                            '& .MuiInputBase-root': { borderRadius: "32px" }
                        }} variant="outlined">
                            <InputLabel htmlFor="Products">{window.location.href.indexOf("search") > -1 ? useParams().query : "Search Anything"}</InputLabel>
                            <OutlinedInput
                                onKeyDown={handleKeyDown}
                                type='text'
                                onChange={captureSearchQuery}
                                placeholder={window.location.href.indexOf("search") > -1 ? useParams().query : "Search Anything"}
                                // value={window.location.href.indexOf("search") > -1 ? useParams().query : ""}
                                endAdornment={
                                    <InputAdornment position="end">
                                        {/*  need to update to actually search */}
                                        <SearchIcon
                                            onClick={() => history.push({
                                                pathname: `/search/${searchQuery}`,
                                                // state: { queryParam: searchQuery,  }
                                            })}
                                            edge="end"
                                            color="primary"
                                            sx={{ cursor: "pointer" }}
                                        />
                                    </InputAdornment>
                                }
                                // label="Search Everything"
                            />
                        </FormControl>
                    )}

                    {/* navRight */}
                    {user.id ? (
                        // <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                        <>
                            <Link sx={{ 
                                fontSize: "2.5vh", fontWeight: "600", cursor: "pointer",
                                marginLeft: "2vh",
                                marginRight: "2vh",
                                display: { xs: 'none', sm: 'none', md:"flex", lg: "flex"  } }} underline="none" onClick={() => history.push("/wishlist")}>Favorites</Link>
                            <Avatar
                                sx={{ backgroundColor: "#167798" }}
                                aria-controls={open ? 'avatar menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleClick}
                            />
                            <Menu
                                anchorEl={dropdown}
                                open={open}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                            >
                                {user.is_streamer && <MenuItem onClick={() => history.push('/payment')}>Payment Portal</MenuItem>}
                                <MenuItem onClick={()=> history.push("/settings")}>Settings</MenuItem>
                                <MenuItem onClick={() => dispatch({ type: "LOGOUT" })}>Logout</MenuItem>
                            </Menu>
                        </>
                    ) : (
                        <Button 
                            onClick={() => history.push('/registration')} 
                            variant="contained" 
                            sx={{ fontFamily: "Poppins", padding: "1.5vh 5vh", fontSize: "2vh" }}>
                                Sign Up
                        </Button>
                    )}
                </Toolbar>
            </Container>
        
        </AppBar>
    )
}

export default Navbar