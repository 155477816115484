import { useParams } from "react-router-dom";
import Chat from "../../Chat/Chat";
import { Box, useTheme, Button, Typography, Card, CardActionArea, CardMedia, Stack, CardContent, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import TooltipMUI from '@mui/material/Tooltip';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

import StreamerControlsProduct from "../StreamerControlsProduct/StreamerControlsProduct";
import ConfirmationPopup from "../../ConfirmationPopup/ConfirmationPopup";
import { socket } from "../../../socket";

// slick slider imports
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ArrowBackIosNew, ArrowBackIosNewTwoTone } from "@mui/icons-material";

function StreamerControls() {
  const { streamID } = useParams();
  const theme = useTheme();
  const dispatch = useDispatch();
  const history = useHistory();

  const [viewerCount, setViewerCount] = useState(0);
  const [displayConfirmEndStream, setDisplayConfirmEndStream] = useState(false);
  const [onLastProduct, setOnLastProduct] = useState();
  const [onFirstProduct, setOnFirstProduct] = useState();

  const currentStream = useSelector((store) => store.currentStream);
  const currentProduct = useSelector((store) => store.currentProduct);
  const user = useSelector((store) => store.user);
  console.log(currentStream)
  let arrayCount = Object.keys(currentStream.products).length

  useEffect(() => {
    dispatch({
      type: "FETCH_STREAM_ON_START_STREAM",
      payload: { streamID: streamID },
    });

    // TODO: send socket emit when a viewer joins a stream
    socket.on("update viewer count", (count) => setViewerCount(count));
    return () => {
      socket.off("update viewer count", (count) => setViewerCount(count));
    };
  }, []);

  const handleChangeProduct = (type) => {
    const product = currentStream.products.find(
      (product) => product.id == currentProduct.id
    );
    const newOrder = type == "previous" && product.order >= 0 ? product.order - 1 : product.order + 1;
    const nextProduct = currentStream.products.find(
      (product) => product.order == newOrder
    );
    dispatch({
      type: "SET_CURRENT_PRODUCT_IN_STREAM",
      payload: { product: nextProduct, streamID: streamID },
    });
  };

  const endStream = () => {
    socket.emit("stream_closed", user.username);
    // TODO: end stream for all users
    dispatch({ type: "END_STREAM", payload: streamID });
    history.push(`/home`);
  };

  // react-slick syntax | docs: https://react-slick.neostack.com/
  let settings = {
    dots: true,
    infinite: true,
    dragable: true,
    // focusOnSelect: true,
    lazyLoad: true,
    speed: 500,
    slidesToShow: 3,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 2,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  }
  // custom style arrows
  function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, borderRadius:"50%", background: "#167798",}}
        onClick={onClick}
      >
      {/* <ArrowBackIosNewIcon backgroundColor="black" color='primary'/> */}
      </div>
    );
  }
  
  function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, borderRadius:"50%", background: "#167798",}}
        onClick={onClick}
      >
      {/* <ArrowForwardIosNewIcon color='primary'/> */}
      </div>
    );
  }

  return (
    <>
      {displayConfirmEndStream && (
        <ConfirmationPopup
          setDisplayConfirmation={setDisplayConfirmEndStream}
          handleConfirm={endStream}
          alertText={`Are you sure you want to end the stream?\n(remember to stop broadcasting the feed)`}
          hidePopupText="CANCEL"
          confirmPopupText="CONFIRM"
          top="38vh"
        />
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            height: "50%",
            width: "100%",
            // justifyContent: "space-between",
            // alignContent: "center",
          }}
        >
          {/* Main Content - Current Product + Catalog */}
          <Box
            sx={{
              width: "67%",
              // backgroundColor: "#CBE5DF",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around", //THISSS
              alignContent: "center",
              position: "relative",
              padding: "16px"
            }}
          >
            <Typography sx={{ margin: "0 0 10px 0", fontWeight: "600" }} align="center" variant="h4" component="div">{currentStream.title}</Typography>
            {/* <Typography sx={{ fontWeight: "600" }} align="center" gutterBottom variant="h4" component="div">Some title</Typography> */}
            <Box>
              {/* Current Product box */}
              <center>
              <Box 
              sx={{
                width: "85%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                backgroundColor: "#fff",
                padding: "8px",
                borderRadius: "10px",
                flexDirection: { xs: "column", lg: "row" }
              }}>
                <Box
                  component="img"
                  src={currentProduct.image_url}
                  // src="/assets/images/product-2.png"
                  alt=""
                  sx={{ width: "50%", height: "424px", borderRadius: "32px", objectFit: "contain", padding: "16px" }}
                />
                <Box sx={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "8px 16px",
                  gap: "8px",
                  textAlign: "justify"
                }}>
                  <div style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    // alignItems: "flex-start",
                    // padding: "8px 16px",
                    // gap: "8px",
                    // textAlign: "justify"
                  }}>
                    <Typography sx={{ fontStyle: 'italic', fontWeight: "500" }} gutterBottom variant="h5" component="div">{currentProduct.name}</Typography>
                    
                  </div>
                  {/* Merchant and Category */}
                  <div style={{
                    display: "flex",
                    margin: "10px 0 0 0",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    // alignItems: "flex-start",
                    // padding: "8px 16px",
                    gap: "15%",
                    // textAlign: "justify"
                  }}>
                    <div>
                      <Typography sx={{margin: "10px 0 0 0", fontWeight: "500", fontSize: "1vw" }} gutterBottom variant="h5" component="div">Merchant</Typography>
                      <Typography sx={{margin: "10px 0 0 0", fontWeight: "500",  }} variant="body2" color="text.secondary">{currentProduct.vendor_name != null ? currentProduct.vendor_name : "BlueOcean"}</Typography>
                    </div>
                    <div>
                      <Typography sx={{margin: "10px 0 0 0", fontWeight: "500", fontSize: "1vw"}} gutterBottom variant="h5" component="div">Category</Typography>
                      <Stack direction="row" spacing={2} sx={{
                        margin:"10px 0 5% 0",
                        display: { xs: "none", md: "inline-flex" },
                      }}>
                        <Button size="large" variant="outlined" sx={{ borderRadius: "32px", fontSize: "12px", color: "black" }}>{currentProduct.category != null ? currentProduct.category : "100% recycled"}</Button>
                      </Stack>
                    </div>
                  </div>
                  {/* Description */}
                  <Typography sx={{ fontWeight: "500", fontSize: "1vw"}} gutterBottom variant="h5" component="div">Description</Typography>
                  <Typography sx={{fontWeight: "500",  }} variant="body2" color="text.secondary">{currentProduct.description}</Typography>
                  {/* Control buttons */}
                  <Stack direction="row" spacing={2} sx={{
                    margin: "10px 0 0 0", 
                    display: { xs: "none", md: "inline-flex" },
                    justifyContent: "space-between",
                    // gap: "10%",
                  }}>
                    {currentProduct.order != 1 &&
                    <Button
                      onClick={() => handleChangeProduct("previous")}
                      size="large"
                      color="primary"
                      sx={{ borderRadius: 50 }}
                    >
                      Previous
                    </Button>
                    }
                    <Button
                      onClick={() => setDisplayConfirmEndStream(true)}
                      size="large"
                      color="warning"
                      sx={{ borderRadius: 50 }}
                    >
                      End Stream
                    </Button>

                    {currentProduct.order != currentStream.products.length &&
                    <Button
                      onClick={() => handleChangeProduct("next")}
                      size="large"
                      color="primary"
                      sx={{ borderRadius: 50 }}
                    >
                      Next
                    </Button>
                    }
                  </Stack>
                </Box>
              </Box>
              </center>
              {/* Stream catalog box */}
              <Box sx={{
                // backgroundColor: "#fff",
                padding: "24px 24px"
              }}>
                <Typography sx={{ margin: "8px 0", fontWeight: "600" }} align="center" variant="h6">Stream Catalog</Typography>

                {/* Bottom Carousel Start */}
                {/* for production start */}
                {arrayCount >= 4 && (
                  <Box align="center">
                    <Slider {...settings}>
                      {arrayCount >= 4 && (
                        currentStream.products.sort((a, b) => a.order - b.order).map((product) => (
                          <StreamerControlsProduct key={product.order} product={product} />
                        )))}
                    </Slider>
                  </Box>
                )}
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                  {arrayCount < 4 && (
                    currentStream.products.sort((a, b) => a.order - b.order).map((product) => (
                      <StreamerControlsProduct key={product.order} product={product} />
                    )))}
                </Box>

                {/* for production end */}

                {/* for development start */}
                {/* <Slider {...settings}>
                    {[...new Array(4)].map((_, i) => (
                      <Card key={i} sx={{ maxWidth: 340, borderRadius: "32px" }}>
                        <CardActionArea>
                          <CardMedia
                            component="img"
                            height="140"
                            image="/assets/images/product-3.png"
                            alt="product"
                          />
                        </CardActionArea>
                        <CardContent>
                          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <Typography noWrap sx={{ display: { xs: "none", lg: "block" } }} gutterBottom variant="h6" component="div">
                              product name {i}
                            </Typography>
                          </Box>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{
                              display: { xs: "none", lg: "-webkit-box" },
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              WebkitLineClamp: 1,
                              WebkitBoxOrient: 'vertical'
                            }}>
                            <TooltipMUI title="Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae voluptas alias quis velit corporis, minus sint totam molestiae possimus consectetur necessitatibus, reiciendis amet accusamus maxime. Est consequuntur cupiditate voluptates reiciendis?">
                              Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae voluptas alias quis velit corporis, minus sint totam molestiae possimus consectetur necessitatibus, reiciendis amet accusamus maxime. Est consequuntur cupiditate voluptates reiciendis?
                            </TooltipMUI>
                          </Typography>
                          <Stack direction="row" spacing={2} sx={{
                            display: { xs: "none", md: "inline-flex" }
                          }}>
                            <Button size="small" variant="outlined" sx={{ borderRadius: "32px", fontSize: "8px", color: "black" }}>100% recycled</Button>
                            <Button size="small" variant="outlined" sx={{ borderRadius: "32px", fontSize: "8px", color: "black" }}>Biodegradable</Button>
                          </Stack>
                        </CardContent>
                      </Card>
                    ))}
                  </Slider> */}
                {/* for development end */}
                {/* Bottom Carousel End */}

              </Box>
            </Box>
          </Box>
          {/* Chat box */}
          <Box
            id="chat"
            sx={{
              width: "33%",
              height: "91.5vh",
              // // backgroundColor: "lightcoral",
              overflow: "hidden",
              // zIndex: 5,
              display: { xs: "none", lg: "block" }
            }}
          >
            <Chat viewerCount={viewerCount} streamId={streamID} />
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default StreamerControls;