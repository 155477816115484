import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { useEffect, useState } from "react";
import { Box, Typography, Container } from '@mui/material'
import Pagination from '@mui/material/Pagination';
import VideoListItem from "./VideoListItem";

// if wishlist is true, then filter only items that have been saved
function ViewerVideoList({ wishlist }) {
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [open, setOpen] = useState(false);
  const streams = useSelector((store) => store.streams.streams)

  // update redux store
  useEffect(() => {
    dispatch({ type: "FETCH_STREAMS" });
  }, []);
  // let products = useSelector((store) => store.allProducts);
//   let products = useSelector((store) => store.wishlist);
  const dispatch = useDispatch();
  const history = useHistory();

  // if (wishlist != null) {
  //   console.log(wishlist)
  // might break after endpoint updates
//   useEffect(() => {
//     dispatch({
//       type: "GET_WISHLIST",
//     });
//   }, [])
//   if (wishlist == true) {
//     // useEffect(() => {
//     //   dispatch({
//     //     type: "GET_WISHLIST",
//     //   });
//     // }, [])
//     products = useSelector((store) => store.wishlist)
//     products = products.filter((product) => 
//       product.on_user_wishlist)
//   }
  // uncomment to get the full allProducts store once on_user_wishlist been added to get products api fetch (reusue get products dispatch)
  // else {
      // useEffect(() => {
      //     dispatch({ type: "GET_PRODUCTS" });
      //   }, []);
  //   products = useSelector((store) => store.allProducts);
  // }

  return (
    <Container maxWidth="xl">
      <Box sx={{
        paddingBottom: "64px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between"
      }}>
        <Typography gutterBottom align='center' sx={{ fontSize: "24px", fontWeight: "600", letterSpacing: "1px", }}>Previous Live Streams</Typography>
        <Box sx={{
          marginBottom: "64px",
          display: "grid",
          gridTemplateColumns: { xs: "repeat(1, minmax(280px, 1fr))", sm: "repeat(2, minmax(250px, 1fr))", md: "repeat(3, minmax(300px, 1fr))"} ,
          gap: "16px",
        }}>
          {streams.length ? (
            (
                streams.map((stream) => (
                <VideoListItem stream={stream} />
              ))
            )
          ) : (
            <Typography variant="body1">
              No streams added yet.
            </Typography>
          )}
        </Box>
        {/* <Pagination onChange={() => { console.log('clicked') }} size='large' count={10} color="primary" /> */}
      </Box>
    </Container>
  );
}

export default ViewerVideoList;
