import axios from "axios";
import { put, takeEvery } from "redux-saga/effects";

const api_url = process.env.REACT_APP_API_URL
const config = {
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
};
// returns true or false for whether the relationship exists
function* sendEmail(action) {
  try {
    yield axios.post(
      `${api_url}/forgot-password`,
      {
        email: action.payload.email,
      },
      config
    );
    yield put({ type: "SEND_EMAIL", payload: true });
  } catch (error) {
    console.log("Error with addProductToStream saga:", error);
  }
}
function* verification(action) {
  try {
    yield axios.post(`${api_url}/verify-user`, {
      email: action.payload.email,
      otac: action.payload.otac,
    }, config);
    yield put({ type: "VERIFY_USER", payload: true });
  } catch (error) {
    console.log("Error with addProductToStream saga:", error);
  }
}

function* resetPassword(action) {
  try {
    yield axios.post(`${api_url}/reset-password`, {
      email: action.payload.email,
      password: action.payload.password,
      confirm_password: action.payload.confirm_password,
    }, config);
    yield put({ type: "RESET_PASSWORD", payload: true });
  } catch (error) {
    console.log("Error with addProductToStream saga:", error);
  }
}

function* forgotPasswordSaga() {
  yield takeEvery("SEND_EMAIL", sendEmail);
  yield takeEvery("VERIFY_USER", verification);
  yield takeEvery("RESET_PASSWORD", resetPassword);
}

export default forgotPasswordSaga;
